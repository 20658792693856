// src/data.js

import img1 from "./assets/CS_2.JPG";
import img2 from "./assets/img2.png";
import img3 from "./assets/img3.png";
import img4 from "./assets/img4.png";
import img5 from "./assets/img5.png";
import img6 from "./assets/img6.png";
import img7 from "./assets/img7.png";
import img8 from "./assets/img8.png";
import img9 from "./assets/img9.png";
import img10 from "./assets/img10.png";

const data = [
  {
    heading: "SafeNet Women : Cybersecurity Empowerment Program",
    paragraph: `The complex technological landscape we navigate has numerous vulnerabilities that demand attention. Cyberattacks and cybercrimes have become integral aspects of our everyday lives, and the field of cybersecurity has emerged as a significant development, offering solutions to these issues.\n\n
 
         As we explored this issue further, we recognized another essential change. Historically, the tech industry has been predominantly represented by men, with limited inclusion of women. Questioning this norm, we aspired to foster inclusivity in the world of machine learning, aiming to bring unity to this field. Women have also faced significant challenges in the realm of cybersecurity, and in response, we've strived to educate and empower them, equipping them with the knowledge to navigate these challenges effectively.\n\n
 
         Cyber Shiksha is a prominent program initiated by Trisakha Foundation in collaboration with the Data Security Council of India (DSCI) and Microsoft. The program's motto is to provide graduate women from Tier-II, Tier-III cities, and rural areas with a comprehensive understanding of cybersecurity through online courses. Each batch comprises 25 women candidates who undergo a rigorous selection, screening, and training process in Artificial Intelligence and Machine Learning. The program serves as a thoughtful remedy to bring diverse perspectives, creative solutions, address gender-based vulnerabilities, and contribute to the increasing need for cybersecurity experts in today’s world.`,
    image: img1,
  },
  {
    heading: "Bright Eyes, Healthy Smiles : Community Health Camps",
    paragraph: `An eye health program conducted by Trisakha Foundation was held on April 10, 2023, at an elderly care home in Washermanpet, Chennai, in collaboration with Uma Eye Clinic. Our team, accompanied by eye specialists, conducted assessments for refractive errors and eye ailments for senior citizens. Concurrently, a dental camp led by Dr. Soumiya Mohan, our renowned dentist, facilitated oral and periodontal health check-ups. As a gesture of care, our doctors provided free guidance on basic oral hygiene and proper brushing techniques to the elderly residents.`,
    image: img2,
  },
  {
    heading: "Tiny Teeth, Big Care : Door-to-Door Dental Check Up",
    paragraph: `On behalf of Trisakha Foundation, a dental check-up for children under six was conducted in Rangapuram, Vellore, on October 16, 2022. Led by our skilled dentist, Dr. Soumia Mohan, and a team of six members, the camp aimed to address dental hygiene issues in young children. During the check-up, common issues such as tooth decay and improper brushing were identified as factors affecting dental health. It was disheartening to observe that every child had at least one decayed tooth.\n\n
             Every child and their parents received education on the importance of dental hygiene and its consequences. Our team provided essential guidance, including brushing teeth twice a day, especially after consuming sugary foods and drinks, consulting a dentist twice a year, and ensuring overall oral hygiene. To address the issue, we distributed individual dental kits and made the brushing process enjoyable for the children.`,
    image: img3,
  },
  {
    heading:
      "Waste to Feed : Harvesting Expired Goods for Nutritional Cattle Feed",
    paragraph: `SS Waste link Sustainability Services PVT, a dedicated animal feed manufacturing company, has joined forces with us to revolutionize the process of converting food waste into nutritious animal feed. In this collaborative initiative, our team diligently collected expired food products from numerous stores across Tamil Nadu and Andhra Pradesh and sent them to Waste Link's specialized unit for processing. The result of this eco-friendly process is Eco-Feed, a high-quality animal feed prepared from expired food products. We proudly present this as a cost-effective and sustainable solution to food brands, eliminating greenhouse gas emissions associated with traditional waste disposal.`,
    image: img4,
  },
  {
    heading: "Assessment of Potential Lead-Contaminated Sites in Tamil Nadu",
    paragraph: `As part of our collaboration with the Bharat Pure Earth Foundation (BPEF), we actively participated in the Toxic Site Identification Program (TSIP), the largest online global inventory of polluted places Our team members conducted on-site visits to contaminated areas, engaging with stakeholders, conducting interviews, and collecting samples. Through these efforts, approximately 30 sites heavily contaminated with lead were identified and assessed across various districts in Tamil Nadu, including Chennai, Tiruvallur, Kancheepuram, Ranipet, Chengalpattu, Vellore, Dharmapuri, Salem, Erode, Coimbatore, Tirupur, Dindigul, Theni, Madurai, Pudukkottai, and Tiruchirapalli. The detailed findings from these examinations have been documented and uploaded to the TSIP online database, accessible through www.contaminatedsites.org.`,
    image: img5,
  },
  {
    heading:
      "Rapid Marketplace Screening (RMS) : An Overview of Our Market Surveys",
    paragraph: `Trisakha Foundation joined hands with Bharat Pure Earth Foundation (BPEF) to conduct an extensive analysis of lead levels in consumer products across 20 cities in Tamil Nadu. Marketplace surveys (RMS) were conducted following a predefined protocol, which included selecting markets, conducting interviews, and collecting samples of products such as spices, major starch, cosmetics, paints, plastic toys, metallic and earthen cookware, etc. The goal was to trace the source of lead contamination.\n\n
             Around 600 samples were collected and subjected to testing. The survey findings highlighted local paints, plastic toys, Indalium, brass, and ceramic cookware as the primary sources of exposure.`,
    image: img6,
  },
  {
    heading: "Health Camps in Erode and Coimbatore : A Wellness Initiative",
    paragraph: `In association with Equitas Small Finance Bank Ltd., health camps were conducted in the villages of Ezuthingalpatti in Erode and Selakkarichal in Coimbatore. Our trained specialists systematically performed tests including Blood pressure, Diabetes, Dental check-ups, Eye examinations, and overall body check-ups.`,
    image: img7,
  },
  {
    heading: `Tracing Lead Sources : Health and Home Assessments in Erode and Coimbatore`,
    paragraph: `The earlier program we conducted for Toxic Site Identification revealed high levels of lead in seven villages in Erode and Coimbatore. In response to this issue, we assessed lactating mothers and approximately 120 children for lead pollution exposure. Home-based assessments and surveys were carried out in households located near industrial areas. To facilitate this survey, we collected samples of spices, paint, cosmetics, dust, toys, and water used by the residents. The success of the project is attributed to the collaboration with Bharat Pure Earth Foundation (BPEF) and the National Referral Centre for Lead Projects in India, Bengaluru. We also received valuable support from Equitas Small Finance Bank Ltd., which played a crucial role in mobilizing the community and providing necessary assistance.`,
    image: img8,
  },
  {
    heading: "Beach Clean : Unite for a Tidy Shore",
    paragraph: `In collaboration with the Indian Maritime Foundation and Bharat Petroleum Corporation Ltd., we conducted a beach clean-up drive in Besant Nagar, near the Ashtalakshmi Temple, on July 15, 2023. The program was inaugurated by Sandeep Rai Rathore, the Police Commissioner of Chennai. Members of the Greater Chennai City Police also actively participated in the drive, promoting their 'Drug-Free Chennai' campaign. Approximately 2000 kgs of waste were collected, sorted by category, and disposed of appropriately.`,
    image: img9,
  },
  {
    heading: " Michaung Cyclone Relief Work",
    paragraph:
      "Cyclone Michaung drastically affected Chennai which is India's fourth largest city and a crucial business center. The cyclone hit northern Tamil Nadu, Chennai and the surrounding districts during the 1st week of December,2023 and disrupted the livelihoods of so many people. Team Trisakha  jumped on the field and contributed to flood relief by distributing food packets and other essentials in the affected areas of Taramani and Velachery.",
    image: img10,
  },
  // Add more objects up to 20
];

export default data;
