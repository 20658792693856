import React, { useEffect, useState } from 'react'
import { Facebook, LinkedIn, Email, Phone } from "@mui/icons-material";
import MainSideLogo from "../assets/image11.png";
import '../css/About.css'
import "../css/HighLite.css";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { styled, IconButton, InputBase } from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import a3 from '../assets/a3.png'
import ceo from '../assets/ceo.png'
import img2 from '../assets/img22.png'
import Team from './Team';
import fb_icon from '../assets/fb_icon.png'
import linkedin_icon from '../assets/linkedin_icon.png'
// import "../css/HeaderComp2.css";
// import HeaderComp2 from './HeaderComp2';
import Navbar from './Navbar';
import FormComponent from './FormComponent';
import DonateModal from './DonateModal';
import logo111 from "../assets/trisakha_logo.png";
import { useLocation } from 'react-router-dom';

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
        color: "red",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white ",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "80%",
    border: "2px solid white",
    borderRadius: "20px",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        width: "100%",
        color: "white",
    },
}));
const About = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };
    const goToHome = () => {
        navigate('/');
    };
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        org_name: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        if (formData.first_name === '' || formData.last_name === '' || formData.phone_number === '' || formData.email === '' || formData.org_name === '' || formData.message === '') {
            alert("Please Fill All The Fields")
        } else {


            axios.post(`https://trisakha.org/upload`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.status === 200) {
                    // console.log(response.status);
                    toast.success("Our Team Will Contact You Soon!", {
                        autoClose: 5000,
                      });
                      setTimeout(() => {
                        window.location.reload()
              
                      }, 5000);
                }
            }).catch((error) => {
                alert("Some Error Occured Contact Admin")

                console.log(error);
            });
        }
        console.log(formData);
    };
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [query1, setQuery1] = useState('');
    const [highlightedText, setHighlightedText] = useState('');
    const [highlightedParagraphs1, setHighlightedParagraphs1] = useState([]);
    const textContent1 = [
        `<span>Trisakha Foundation</span>, established by <span>Mr. Sai Krishna BVS</span>, is an independent, non-profit organization headquartered in <span>Chennai, Tamil Nadu, India</span>. Committed to the conservation of natural resources and the well-being of communities under the <span>Indian Trust Act of 1882</span>, our actions are guided by the principles of Iccha (will) and <span>Jnana</span> (knowledge), reflected in our name - Trisakha, denoting the <span>Trifoliate or Bilva Patra leaf</span>.
                        <br/>As represented by the three leaves in our logo, our purpose revolves around the three aspects of sustainability - <span>People, Planet</span>, and <span>Profit</span>. At Trisakha, we dream of a world where individuals thrive in health, living in perfect harmony amidst the healthiest environment possible.
                        <br/>Trisakha Foundation, as a Trust, is dedicated to being the helping hand that stimulates positive change in the world we pass on to our loved ones, addressing concerns within natural habitats and the communities dependent on them through effective means. While our initial focus was on environmental concerns, our commitment has expanded to empower women graduates from economically disadvantaged backgrounds and provide access to proper and essential education. Our formula for nation-building revolves around the three E’s - Education, Empowerment, and Environment. Our journey began with a group of passionate and determined individuals, motivated by the mission to raise awareness about environmental issues and encourage sustainable practices.
                        <br/>Dedicated to leaving positive footprints on the environment, we pave the way through initiatives like education and outreach, research and monitoring, and impactful direct action. In the spirit of collective responsibility, Trisakha emphasizes the individual's role in nurturing our environment. Our journey is enriched by the constant support of donors, volunteers, and partners, addressing a multitude of environmental challenges.`,
        `Our Mission`,
        `To contribute to the building of a stronger nation by prioritizing education, empowerment through skill development, and sustainable practices for a healthier environment.`,
        `Our Vision`,
        `Inspiring communities to flourish in health and self-sufficiency amid an environment of continual betterment. `,
        `Who We Are ?`,
        `What We Do ?`,
        `We operate as a unified team, where diverse expertise in medicine, health, environment, food, social sciences, and policy development intertwine, all marching towards the same motto. Calling ourselves the Guardians of Green, we toil for the benefit of the soil.`,
        `Home`,
        `About Us`,
        `Initiatives`,
        `Blogs`,
        `Training`,
    ];

    const highlightSearch = () => {
        const text = query1;
        if (!text) {
            setHighlightedParagraphs1(textContent1); // Reset if query is empty
            return;
        }

        const queryRegex = new RegExp(`(${text})`, 'gi');
        const highlighted = textContent1.map(paragraph =>
            paragraph
                .replace(/(<span class="highlight">|<\/span>)/gi, '') // Remove existing highlights
                .replace(queryRegex, '<span class="highlight">$1</span>')
        );

        setHighlightedParagraphs1(highlighted);
    };

    useEffect(() => {
        highlightSearch();
    }, [query1]);
    
    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
    return (
        <>
            <DonateModal open={open} handleClose={handleClose} />
            <header className="">
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    {/* <Navbar /> */}
                    <nav className="navbar navbar-expand-lg ">
                        {/* <a className="navbar-brand" href="#">Trisakha</a> */}
                        {/* <img src={logo} alt="Logo" className="navbar-brand" width={70} />
        <img src={logo} alt="Logo" className="logo" /> */}
                        <img onClick={goToHome} src={logo111} alt="Logo" className="navbar-brand" width={70} />
                        <img onClick={goToHome} src={logo111} alt="Logo" className="logos" />
                        {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> */}
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={toggleNavbar}
                            aria-controls="navbarSupportedContent"
                            aria-expanded={isCollapsed}
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto nav_ul">
            <li className="nav-item active">
              <a className="nav-link"  ><NavLink to="/" exact active ClassName="active" className="nav-link">
            <span  dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[8] }}/>
              </NavLink><span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
              <a className="nav-link"  ><NavLink to="/about" active ClassName="active" className="nav-link">
            <span  dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[9] }}/>
              </NavLink></a>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#"><NavLink to="/initiatives" active ClassName="active" className="nav-link"> */}
              <a className="nav-link"  ><NavLink to="/initiatives" active ClassName="active" className="nav-link">
            <span  dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[10] }}/>
              </NavLink></a>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#"><NavLink to="/blogs" active ClassName="active" className="nav-link"> */}
              <a className="nav-link"  ><NavLink  to="/blogs" active ClassName="active" className="nav-link">
            <span  dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[11] }}/>
              </NavLink></a>
            </li>
            <li className="nav-item" >
              <a className="nav-link"  > <NavLink onClick={(e) => e.preventDefault()} to="/training" active ClassName="active" className="nav-link">
            <span  dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[12] }}/>
              </NavLink></a>
            </li>
            <form onSubmit={(e) => e.preventDefault()}>

                                </form>
                                <form className="form-inline my-2 my-lg-0">
                                    <div className='searchBar_cls' style={{ position: "relative" }}>
                                        <input className="form-control mr-sm-2 searchBar" value={query1}
                                            onChange={(e) => setQuery1(e.target.value)} name="query1" type="text" placeholder="Search" aria-label="Search" style={{ paddingLeft: "30px" }} />
                      <SearchIcon id='search_icon' />
                                    </div>
                                </form>
                            </ul>
                        </div>
                    </nav>

                    <div className="header-top-right" style={{ paddingTop: "1rem", marginRight: "2rem" }}>
                        <div className="social-icons">
                            <IconButton className="contact-button linkedin-icon">
                                <Phone />
                            </IconButton>
                            <IconButton className="contact-button">
                                <Email />
                            </IconButton>
                        </div>
                        <div className="social-icons2">
                            <IconButton className="">
                                {/* <Facebook /> */}
                                <img src={fb_icon} width={45} style={{ marginRight: "-2px" }} />
                            </IconButton>
                            <IconButton className="">
                                <img src={linkedin_icon} width={45} style={{ marginRight: "-5px" }} />

                                {/* <LinkedInIcon /> */}
                                {/* <LinkedIn /> */}
                            </IconButton>
                        </div>
                    </div>
                </div>

                <div className="contentAndLogoContainer2">
                    <div className="header-banner2">
                        <h2 className='about_us' >About Us</h2>
                        <div className="buttons2">
                            <button className="volunteer1">VOLUNTEER</button>
                            <button className="donate1" onClick={handleOpen}>DONATE</button>
                        </div>
                    </div>
                    <div className="subContentAndLogoContainer1">
                        <img
                            src={MainSideLogo}
                            alt="MainSideLogo"
                            className="MainSideLogo1"
                        />
                    </div>
                </div>
            </header>

            <div className="grid-container">
                <div className="item1" id="who-we-are">
                    <p className='div2_h' dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[5] }} />
                </div>
                <div className="item2" id='who_we_are_div' >
                    <div className='ceo_img'>
                        <img src={ceo} height={200} />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[0] }} />
                </div>
                <div className="item3">
                    <div className='div2_right'>
                        <img src={ceo} height={200} />
                    </div>
                    <div className='div2_right_bottom'>
                        <img src={img2} height={200} />
                    </div>
                </div>

            </div>
            <div className='div_3'>
                <div className='div_3_boxes_1' >
                    <h2 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[1] }} />
                    <img src={img2} height={150} style={{ position: "absolute", opacity: "0.3" }} alt='missionimg1' />


                    <p className='box_p' dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[2] }} />

                </div>
                <div className='div_3_boxes_2' style={{ color: "white", fontFamily: 'Roboto Condensed' }} >
                    <img src={img2} height={150} style={{ position: "absolute", opacity: "0.3" }} alt='missionimg2' />

                    <h2 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[3] }} />

                    <p className='box_p' dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[4] }} />
                </div>
            </div><section id="what-we-do">
            </section>
            <div className="grid-container" >
                <div className="item1">
                    <p className='div2_h' dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[6] }} />
                </div>  
                <div className="item2">

                    <p dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[7] }} />
                </div> 
              
                <div className="item3" >
                    <div className='div2_right'>
           
                        <img src={a3} height={240} style={{ marginRight: "2rem" }}  />

                    </div>
                </div>
            </div>
            <div   style={{ boxSizing: "border-box", backgroundColor: "#ebffdf", margin: "0" }} >
            <Team />
            </div>
            <div className='team'  >
            </div>
            <div style={{ height: "50px", backgroundColor: "#0c9d6d", marginTop: "2rem" }}></div>
            <div className='contact_form_component'>
                <FormComponent />
            </div>
            <h1  className='div6_h contact_form' align='center' style={{ paddingBottom: "3rem", paddingTop: "2rem", backgroundColor: "white", margin: "0px" }}>SCHEDULE A MEETING</h1>
            <div className='div_6 contact_form' style={{ backgroundColor: "" }}>
                <div style={{ paddingTop: "0rem", background: "linear-gradient(180deg, rgba(255,255,255,1) 27%, rgba(255,255,255,0) 100%)" }}>
                    <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", }}>
                        <div className="col-lg-7">
                            <div className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                                <div className="row gy-4" >
                                    <div style={{ display: "flex", gap: "" }}>
                                        <div className="row gy-4" style={{ margin: "0px" }}>
                                            <div className="col-md-5">
                                                <label style={{ fontWeisssght: "bold", color: "#141C48" }} for="name-field" className="pb-2">First Name</label>
                                                <input
                                                    required
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" name="first_name" id="name-field" className="form-control" />
                                            </div>

                                            <div className="col-md-5">
                                                <label style={{ fontWeisssght: "bold", color: "#141C48" }} for="lastname-field" className="pb-2">Last Name</label>
                                                <input
                                                    required
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" className="form-control" name="last_name" id="email-field" />
                                            </div>
                                            <div className="col-md-10">
                                                <label style={{ fontWeigsssht: "bold", color: "#141C48" }} for="phonenumber-field" className="pb-2">Phone Number</label>
                                                <input
                                                    required
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="number" className="form-control" name="phone_number" id="email-field" />
                                            </div>
                                            <div className="col-md-10" style={{ marginTop: "10px" }}>
                                                <label style={{ fontWessight: "bold", color: "#141C48" }} for="email-field" className="pb-2">Email ID</label>
                                                <input
                                                    required
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="email" className="form-control" name="email" id="email-field" />
                                            </div>
                                            {/* </div> */}
                                            {/* <div className="row gy-4" > */}
                                            <div className="col-md-10" style={{ marginTop: "0px" }}>
                                                <label style={{ fontWssseight: "bold", color: "#141C48" }} for="subject-field" className="pb-2">Organization Name</label>
                                                <input
                                                    required
                                                    value={formData.org_name}
                                                    onChange={handleChange}
                                                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" className="form-control" name="org_name" id="subject-field" />
                                            </div>
                                            <div className="col-md-10" style={{ marginTop: "0px" }}>
                                                <label style={{ fontWssseight: "bold", color: "#141C48" }} for="message-field" className="pb-2">Message</label>
                                                <textarea
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} className="form-control" name="message" rows="5" id="message-field"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <button
                                        onClick={handleSubmit}
                                        style={{ border: "none", backgroundColor: "#304145", height: "40px", width: "100px", borderRadius: "5px", color: "white" }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About