// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight {
    background-color: yellow !important;
    color: black;
  }`, "",{"version":3,"sources":["webpack://./src/css/HighLite.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,YAAY;EACd","sourcesContent":[".highlight {\n    background-color: yellow !important;\n    color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
