// src/components/Header.js
import React, { useEffect, useRef, useState } from 'react'
import { Facebook, LinkedIn, Email, Phone } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, IconButton } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../css/HeaderComp.css";
import "../css/HighLite.css";
import "../css/HomePage.css";
import fb_icon from '../assets/fb_icon.png'
import linkedin_icon from '../assets/linkedin_icon.png'
import MainSideLogo from "../assets/mainpageSideLogo.png";
import descriptionlogo from "../assets/descriptionSideLogo.png";
import middledescriptionlogo1 from "../assets/Jaya & kids 1.png";
import middledescriptionlogo2 from "../assets/what-we-do2.png";
import middledescriptionlogo3 from "../assets/6 2.png";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import middleTree from "../assets/middleTreeLeafs1.png";
import middleTreeSeconds from "../assets/middleTreeLeafs2.png";
import treeimg from '../assets/treeimg.png'
import leafs from '../assets/LeftLeaf1.png'
import leaf2 from '../assets/RightLeaf1.png'
import tringleaf from "../assets/trinagle_leaf.png";
import trangle from "../assets/triangle_main.png";
import triangleCenterImg from "../assets/trianglecenter.png";
import last_img from '../assets/last_img.png'
import Navbar from "./Navbar";
import axios from 'axios';
import FormComponent from './FormComponent';
import DonateModal from './DonateModal';
import img1_strip from '../assets/aa1.jpg'
import img2_strip from '../assets/as1.jpg'
import img3_strip from '../assets/chandru_sir.jpg'
import img4_strip from '../assets/we41.jpg'
import img5_strip from '../assets/ww21.jpg'
import img6_strip from '../assets/w66.jpg'
import img7_strip from '../assets/t77.jpg'
import img9_strip from '../assets/W1122.jpg'
import img10_strip from '../assets/t55.jpg'
import img11_strip from '../assets/t1.jpeg'
import img12_strip from '../assets/img7.png'
import img13_strip from '../assets/W3344.jpg'
import img14_strip from '../assets/w1.jpg'
import img15_strip from '../assets/t8.jpeg'
import img16_strip from '../assets/t6.jpeg'
import img17_strip from '../assets/c11.png'
import img18_strip from '../assets/c2.jpg'
import img19_strip from '../assets/c3.jpg'
import img20_strip from '../assets/c4.jpg'
import img21_strip from '../assets/c5.jpg'

import '../css/Navbar.css'
import logo111 from "../assets/trisakha_logo.png";
const Homepage = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const navigate = useNavigate();
  const goToHome = () => {
    navigate('/');
  };
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    org_name: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    console.log(process.env.REACT_APP_API_URL)
    e.preventDefault();
    if (formData.first_name === '' || formData.last_name === '' || formData.phone_number === '' || formData.email === '' || formData.org_name === '' || formData.message === '') {
      alert("Please Fill All The Fields")
    } else {

      axios.post(`https://trisakha.org/upload`, formData, {


        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.status === 200) {
          // console.log(response.status);
          toast.success("Our Team Will Contact You Soon!", {
            autoClose: 5000,
          });
          setTimeout(() => {
            window.location.reload()

          }, 5000);
        }
      }).catch((error) => {
        alert("Some Error Occured Contact Admin")

        console.log(error);
      });
    }
    console.log(formData);
  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const images = [
    img1_strip,
    img2_strip,
    img3_strip,
    img4_strip,
    img5_strip,
    img6_strip,
  ];

  const [searchText, setSearchText] = useState('');
  const contentRef = useRef(null);

  const handleSearch = (event) => {
    const text = event.target.value;
    setSearchText(text);
  };

  useEffect(() => {
    if (!contentRef.current) return;

    let content = contentRef.current.innerHTML;

    if (searchText) {
      const regex = new RegExp(`(${searchText})`, 'gi');
      content = content.replace(regex, '<mark>$1</mark>');
    } else {
      // Reset the content if the searchText is cleared
      content = contentRef.current.innerHTML = contentRef.current.textContent;
    }

    contentRef.current.innerHTML = content;
  }, [searchText]);
  const [query, setQuery] = useState('');
  const [highlightedText, setHighlightedText] = useState('');
  const [highlightedParagraphs, setHighlightedParagraphs] = useState([]);
  const textContent = [
    `Empower through Education<br/> Preserve through Action`,
    `Trisakha Foundation is an independent, non-profit organization
    incorporated under the Indian Trust Act of 1882. Headquartered in
    Chennai, Tamil Nadu, India. <br /><br /> Our journey began with a group of
    passionate and determined individuals, motivated by the passion to
    bridge the existing gaps in the technical skillsets as well as
    contribute to addressing the environmental, public health, food &
    Nutrition and WASH issues.<br /><br /> Trisakha is committed to contribute
    towards upskilling the young talent pool in the cybersecurity
    domain; implementing waste management solutions; supporting R & D
    initiatives; conserving natural resources and the well-being of
    communities that will in turn contribute to nation-building.`,
    `Assessment of Potential<br /> Lead-Contaminated Sites<br /> in Tamil Nadu`,
    `Tiny Teeth, Big Care :<br /> <span>Door-to-Door Dental<br /> Check up</span>`,
    `SafeNet Women :<br /> <span> Cybersecurity<br /> Empowerment Program</span>`,
    `Waste to Feed :<br /> <span> Harvesting Expired<br /> Goods for Nutritional<br /> Cattle Feed</span>`,
    `Bright Eyes, Healthy<br /> Smiles :<span> Community <br />Health Camps</span>`,
    `Our <br /> Initiatives`,
    `Beach Clean : <span>Unite <br />for a Tidy Shore</span>`,
    `Health Camps in Erode<br /> and Coimbatore : A<br /><span> Wellness Initiative</span>`,
    `Michaung Cyclone<br /> Relief Work`,
    `Tracing Lead Sources :<br /><span> Health and Home<br /> Assessments in Erode<br /> and Coimbatore</span>`,
    `Rapid Marketplace<br /> Screening (RMS) : <span>An<br /> Overview of Our Market<br /> Surveys</span>`,

    `“Trisakha” is a term born from a harmonious connection to Lord
    Shiva's Trident and the sacred Bilva Patra Tree in Hinduism,
    signifying a deep spiritual bond. The word “Trisakha” implies
    three branches, each representing Icha Shakthi - the energy of
    will power, Jnana Shakthi - the energy of knowledge, and Kriya
    Shakthi - the energy of hard work. The will to produce a crucial
    transformation in the environment we operate, the profound
    knowledge to instigate essential measures that preserve and
    prosper the environment. More importantly, it emphasizes
    impactful actions as the fruitful outcome of Will and Knowledge,
    serving as the driving force behind Trisakha's mission.`,
    `Home`,
    `About Us`,
    `Initiatives`,
    `Blogs`,
    `Training`,
  ];

  const highlightSearch = () => {
    const text = query;
    if (!text) {
      setHighlightedParagraphs(textContent); // Reset if query is empty
      return;
    }

    const queryRegex = new RegExp(`(${text})`, 'gi');
    const highlighted = textContent.map(paragraph =>
      paragraph
        .replace(/(<span class="highlight">|<\/span>)/gi, '') // Remove existing highlights
        .replace(queryRegex, '<span class="highlight">$1</span>')
    );

    setHighlightedParagraphs(highlighted);
  };

  useEffect(() => {
    highlightSearch();
  }, [query]);
  const handleLeafClick = (index) => {
    console.log("index", index);
    navigate("/initiatives", { state: { activeIndex: index } });
  };
  return (
    <>
      <div id="searchtext">
        <DonateModal open={open} handleClose={handleClose} />
        <header className="">
          {/* <HeaderComp/> */}
          <div style={{ display: 'flex', justifyContent: "space-between" }}>
            {/* <Navbar /> */}
            <nav className="navbar navbar-expand-lg ">
              {/* <a className="navbar-brand" href="#">Trisakha</a> */}
              {/* <img src={logo} alt="Logo" className="navbar-brand" width={70} />
        <img src={logo} alt="Logo" className="logo" /> */}
              <img onClick={goToHome} src={logo111} alt="Logo" className="navbar-brand" width={70} />
              <img onClick={goToHome} src={logo111} alt="Logo" className="logos" />
              {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> */}
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
                aria-controls="navbarSupportedContent"
                aria-expanded={isCollapsed}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto nav_ul">
                  <li className="nav-item active">
                    <a className="nav-link"  ><NavLink to="/" exact active ClassName="active" className="nav-link">
                      <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs[14] }} />
                    </NavLink><span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link"  ><NavLink to="/about" active ClassName="active" className="nav-link">
                      <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs[15] }} />
                    </NavLink></a>
                  </li>
                  <li className="nav-item">
                    {/* <a className="nav-link" href="#"><NavLink to="/initiatives" active ClassName="active" className="nav-link"> */}
                    <a className="nav-link"  ><NavLink to="/initiatives" active ClassName="active" className="nav-link">
                      <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs[16] }} />
                    </NavLink></a>
                  </li>
                  <li className="nav-item">
                    {/* <a className="nav-link" href="#"><NavLink to="/blogs" active ClassName="active" className="nav-link"> */}
                    <a className="nav-link"  ><NavLink to="/blogs" active ClassName="active" className="nav-link">
                      <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs[17] }} />
                    </NavLink></a>
                  </li>
                  <li className="nav-item" >
                    <a className="nav-link"  > <NavLink onClick={(e) => e.preventDefault()} to="/training" active ClassName="active" className="nav-link">
                      <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs[18] }} />
                    </NavLink></a>
                  </li>
                  <form onSubmit={(e) => e.preventDefault()}>

                  </form>
                  <form className="form-inline my-2 my-lg-0">
                    <div className='searchBar_cls' style={{ position: "relative" }}>
                      <input className="form-control mr-sm-2 searchBar" value={query}
                        onChange={(e) => setQuery(e.target.value)} name="query" type="text" placeholder="Search" aria-label="Search" style={{ paddingLeft: "30px" }} />
                      <SearchIcon id='search_icon' />
                    </div>
                  </form>
                </ul>
              </div>
            </nav>

            <div className="header-top-right" style={{ paddingTop: "1rem", marginRight: "2rem" }}>
              <div className="social-icons">
                <IconButton className="contact-button linkedin-icon">
                  <Phone />
                </IconButton>
                <IconButton className="contact-button">
                  <Email />
                </IconButton>
              </div>
              <div className="social-icons2">
                <IconButton className="">
                  {/* <Facebook /> */}
                  <img src={fb_icon} width={45} style={{ marginRight: "-2px" }} />
                </IconButton>
                <IconButton className="">
                  <img src={linkedin_icon} width={45} style={{ marginRight: "-5px" }} />

                  {/* <LinkedInIcon /> */}
                  {/* <LinkedIn /> */}
                </IconButton>
              </div>
            </div>
          </div>
          <div className="contentAndLogoContainer2">
            <div className="header-banner2">
              {/* <h2 className="firstLineText">Empower through Education</h2> */}
              <h2 className="firstLineText" dangerouslySetInnerHTML={{ __html: highlightedParagraphs[0] }} />
              {/* <h2 className="firstLineText" dangerouslySetInnerHTML={{ __html: highlightedParagraphs[1] }}/> */}
              <div className="buttons2">
                <button className="volunteer1">VOLUNTEER</button>
                <button className="donate1" onClick={handleOpen}>DONATE</button>
              </div>
            </div>
            <div className="subContentAndLogoContainer1">
              <img
                src={MainSideLogo}
                alt="MainSideLogo"
                className="MainSideLogo"
              />
            </div>
          </div>
        </header>
        {/* <div style={{ height: "20px", backgroundColor: "#0c9d6d", marginTop: "2rem" }}></div> */}


        {/* description section starts here */}
        <div className="description_section_wrapper">
          <div className='slider'>
            <div className='slide-track'>
              <div className='slide'>
                <img src={img10_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img1_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img2_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img3_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img4_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img5_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img6_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img7_strip} alt="Group Logo" className="groupLogo17" />

              </div>

              <div className='slide'>
                <img src={img9_strip} alt="Group Logo" className="groupLogo17" />

              </div>

              <div className='slide'>
                <img src={img11_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img12_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img13_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img14_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img15_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img16_strip} alt="Group Logo" className="groupLogo17" />

              </div>
 
              <div className='slide'>
                <img src={img18_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img19_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img20_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img21_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              {/* ------------------------------- */}
              <div className='slide'>
                <img src={img10_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img1_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img2_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img3_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img4_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img5_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img6_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img7_strip} alt="Group Logo" className="groupLogo17" />

              </div>

              <div className='slide'>
                <img src={img9_strip} alt="Group Logo" className="groupLogo17" />

              </div>

              <div className='slide'>
                <img src={img11_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img12_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img13_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img14_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img15_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img16_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              {/* ---------------------------------------- */}

              <div className='slide'>
                <img src={img10_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img1_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img2_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img3_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img4_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img5_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img6_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img7_strip} alt="Group Logo" className="groupLogo17" />

              </div>

              <div className='slide'>
                <img src={img9_strip} alt="Group Logo" className="groupLogo17" />

              </div>

              <div className='slide'>
                <img src={img11_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img12_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img13_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img14_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img15_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              <div className='slide'>
                <img src={img16_strip} alt="Group Logo" className="groupLogo17" />

              </div>
              {/* ---------------------------------------- */}

            


            </div>
          </div>
          <div className="image_description">

          </div>
          <div className="description">
            <div className="imgSections">
              <img
                src={descriptionlogo}
                alt="Description Logo"
                className="descriptionLogo"
              />
            </div>
            <div className="descriptionText" id="searchtext">
              <p dangerouslySetInnerHTML={{ __html: highlightedParagraphs[1] }} />
            </div>
          </div>
          <div className="middleThreeImageSec" id="searchtext">
            <div className="middleImageSec">
              <a className="nav-link"  >
                <NavLink to="/about#who-we-are" active ClassName="active" className="nav-link">
                  <p>Who We Are ?</p>
                
              <img
                src={middledescriptionlogo1}
                alt="Description Logo"
                className="middledescriptionLogo"
              />
              </NavLink></a>
            </div>
            <div className="middleImageSec">
            <a className="nav-link"  >
                <NavLink to="/about#what-we-do" active ClassName="active" className="nav-link">
              <p>What We Do ?</p>
               
              <img
                src={middledescriptionlogo2}
                alt="Description Logo"
                className="middledescriptionLogo"
              />
               </NavLink></a>
            </div>
            <div className="middleImageSec">
            <a className="nav-link"  >
                <NavLink to="/about#our-team" active ClassName="active" className="nav-link">
              <p className='ourTeamText'>Our Team</p>
                
              <img
                src={middledescriptionlogo3}
                alt="Description Logo"
                className="middledescriptionLogo"
              />
              </NavLink></a>
            </div>
          </div>
          <div style={{ height: "50px", backgroundColor: "#0c9d6d", marginTop: "2rem" }}></div>

          <div className="initiatives_section_wrapper" id="searchtext">
            <div className="middleTreeFirst1">
              <span className="middletext_hide">Our <br /> Initiatives</span>

              <img src={middleTree} alt="Logo" className="logo" id="firstimg" />

              <span className="middletext">Our <br /> Initiatives</span>

              <img src={middleTreeSeconds} alt="Logo" className="logo" />
            </div>

            <div className="middleTreeFirst2">
              <div className="tree_div">
                <img
                  src={treeimg}
                  width={360}
                  alt="Logo"
                  className="losgo"
                  id="firstimg"
                />
                <img
                  alt=""
                  src={leafs}
                  width={260}
                  className="leaf1"
                  onClick={() => handleLeafClick(4)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p1"
                  onClick={() => handleLeafClick(4)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[2]}`,
                  }}
                />

                <img
                  alt=""
                  src={leafs}
                  width={260}
                  className="leaf2"
                  onClick={() => handleLeafClick(2)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p2"
                  onClick={() => handleLeafClick(2)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[3]}`,
                  }}
                />
                <img
                  alt=""
                  src={leafs}
                  width={260}
                  className="leaf3"
                  onClick={() => handleLeafClick(0)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p3"
                  onClick={() => handleLeafClick(0)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[4]}`,
                  }}
                />
                <img
                  alt=""
                  src={leaf2}
                  width={260}
                  className="right1"
                  onClick={() => handleLeafClick(3)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p4"
                  onClick={() => handleLeafClick(3)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[5]}`,
                  }}
                />
                <img
                  alt=""
                  src={leaf2}
                  width={260}
                  className="right2"
                  onClick={() => handleLeafClick(1)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p5"
                  onClick={() => handleLeafClick(1)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[6]}`,
                  }}
                />
              </div>
              <span
                className="middletext"
                dangerouslySetInnerHTML={{ __html: highlightedParagraphs[7] }}
              />

              <div className="tree_div">
                <img
                  src={treeimg}
                  width={360}
                  alt="Logo"
                  style={{ transform: "scaleX(-1)" }}
                  className="losgo"
                  id="firstimg"
                />
                <img
                  alt=""
                  src={leafs}
                  width={260}
                  className="leaf1_right"
                  onClick={() => handleLeafClick(8)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p1_right"
                  onClick={() => handleLeafClick(8)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[8]}`,
                  }}
                />
                <img
                  alt=""
                  src={leafs}
                  width={260}
                  className="leaf2_right"
                  onClick={() => handleLeafClick(6)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p2_right"
                  onClick={() => handleLeafClick(6)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[9]}`,
                  }}
                />
                <img
                  alt=""
                  src={leaf2}
                  width={260}
                  className="right1_leaf"
                  onClick={() => handleLeafClick(9)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p4_leaf"
                  onClick={() => handleLeafClick(9)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[10]}`,
                  }}
                />
                <img
                  alt=""
                  src={leaf2}
                  width={260}
                  className="right2_leaf"
                  onClick={() => handleLeafClick(7)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p5_leaf"
                  onClick={() => handleLeafClick(7)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[11]}`,
                  }}
                />
                <img
                  alt=""
                  src={leaf2}
                  width={260}
                  className="right3_leaf"
                  onClick={() => handleLeafClick(5)}
                  style={{ cursor: "pointer" }}
                />
                <h4
                  className="leaf_p6_leaf"
                  onClick={() => handleLeafClick(5)}
                  style={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: `${highlightedParagraphs[12]}`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>


        <div className="purpose_section">
          <div className="sub_purpose_section">
            <div className="text_purpose_section" id="searchtext"  >
              <h3><b>Through Our Lens</b></h3>
              {/* <p id="searchtext" dangerouslySetInnerHTML={{ __html: highlightedText[1] || textContent }} /> */}
              <p dangerouslySetInnerHTML={{ __html: highlightedParagraphs[13] }} />

            </div>
            <div className="image_purpose_section">
              <img
                src={trangle}
                alt="Purpose_Image"
                className="purposeImage baseImage"
              />
              <img
                src={tringleaf}
                alt="Purpose_Image"
                className="purposeImage overlayImage"
              />
              <img
                src={triangleCenterImg}
                alt="Purpose_Image"
                className="purposeImage topImage"
              />
            </div>
            <div className='show_img'>
              <img src={last_img} className='last_img' />

            </div>
          </div>
        </div>

        <div style={{ height: "50px", backgroundColor: "#0c9d6d", marginTop: "" }}></div>

        <div className='contact_form_component'>
          <FormComponent />
        </div>
        <h1 className='div6_h contact_form' align='center' style={{ paddingBottom: "3rem", paddingTop: "2rem", backgroundColor: "white", margin: "0px" }}>SCHEDULE A MEETING</h1>
        <div className='div_6 contact_form' style={{ backgroundColor: "" }}>
          <div style={{ paddingTop: "0rem", background: "linear-gradient(180deg, rgba(255,255,255,1) 27%, rgba(255,255,255,0) 100%)" }}>
            <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", }}>
              <div className="col-lg-7">
                <div className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                  <div className="row gy-4" >
                    <div style={{ display: "flex", gap: "" }}>
                      <div className="row gy-4" style={{ margin: "0px" }}>
                        <div className="col-md-5">
                          <label style={{ fontWeisssght: "bold", color: "#141C48" }} for="name-field" className="pb-2">First Name</label>
                          <input
                            required
                            value={formData.first_name}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" name="first_name" id="name-field" className="form-control" />
                        </div>

                        <div className="col-md-5">
                          <label style={{ fontWeisssght: "bold", color: "#141C48" }} for="lastname-field" className="pb-2">Last Name</label>
                          <input
                            required
                            value={formData.last_name}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" className="form-control" name="last_name" id="email-field" />
                        </div>
                        <div className="col-md-10">
                          <label style={{ fontWeigsssht: "bold", color: "#141C48" }} for="phonenumber-field" className="pb-2">Phone Number</label>
                          <input
                            required
                            value={formData.phone_number}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="number" className="form-control" name="phone_number" id="email-field" />
                        </div>
                        <div className="col-md-10" style={{ marginTop: "10px" }}>
                          <label style={{ fontWessight: "bold", color: "#141C48" }} for="email-field" className="pb-2">Email ID</label>
                          <input
                            required
                            value={formData.email}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="email" className="form-control" name="email" id="email-field" />
                        </div>
                        {/* </div> */}
                        {/* <div className="row gy-4" > */}
                        <div className="col-md-10" style={{ marginTop: "0px" }}>
                          <label style={{ fontWssseight: "bold", color: "#141C48" }} for="subject-field" className="pb-2">Organization Name</label>
                          <input
                            required
                            value={formData.org_name}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" className="form-control" name="org_name" id="subject-field" />
                        </div>
                        <div className="col-md-10" style={{ marginTop: "0px" }}>
                          <label style={{ fontWssseight: "bold", color: "#141C48" }} for="message-field" className="pb-2">Message</label>
                          <textarea
                            value={formData.message}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} className="form-control" name="message" rows="5" id="message-field"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button
                      onClick={handleSubmit}
                      style={{ border: "none", backgroundColor: "#304145", height: "40px", width: "100px", borderRadius: "5px", color: "white" }}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;