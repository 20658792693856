import React, { useState, useLayoutEffect, useRef, useCallback } from "react";
import { Facebook, LinkedIn, Email, Phone } from "@mui/icons-material";
import { styled, IconButton, InputBase } from "@mui/material";
import MainSideLogo from "../assets/image11.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import fb_icon from '../assets/fb_icon.png'
import linkedin_icon from '../assets/linkedin_icon.png'
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../css/Content.css"; // Import your custom styles
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import data from "../data";
import FormComponent from "./FormComponent";
import Navbar from "./Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DonateModal from "./DonateModal";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import logo111 from "../assets/trisakha_logo.png";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
// Add icons to the library
library.add(faChevronLeft, faChevronRight);

const CustomArrow = ({ onClickHandler, label }) => (
  <button
    type="button"
    onClick={onClickHandler}
    className={`custom-arrow ${label}`}
    aria-label={label}
  >
    <FontAwesomeIcon icon={label === "prev" ? faChevronLeft : faChevronRight} />
  </button>
);

export default function Initiative2() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const goToHome = () => {
    navigate('/');
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselContainerRef = useRef(null);
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    org_name: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.first_name === '' || formData.last_name === '' || formData.phone_number === '' || formData.email === '' || formData.org_name === '' || formData.message === '') {
      alert("Please Fill All The Fields")
    } else {

      axios.post(`https://trisakha.org/upload`, formData, {


        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.status === 200) {
          // console.log(response.status);
          toast.success("Our Team Will Contact You Soon!", {
            autoClose: 5000,
          });
          setTimeout(() => {
            window.location.reload()
  
          }, 5000);
        }
      }).catch((error) => {
        alert("Some Error Occured Contact Admin")

        console.log(error);
      });
    }
    console.log(formData);
  }

  const updateHeight = useCallback(() => {
    if (carouselContainerRef.current) {
      const activeItem = carouselContainerRef.current.querySelector(
        ".carousel-item.active"
      );
      if (activeItem) {
        const height = activeItem.clientHeight;
        carouselContainerRef.current.style.height = `${height}px`;
      }
    }
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      updateHeight();
    };

    window.addEventListener("resize", handleResize);
    updateHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateHeight]);

  const handleImageLoad = () => {
    updateHeight();
  };

  const handleChange2 = (index) => {
    setCurrentIndex(index);
    updateHeight(); // Ensure height update on slide change
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [query1, setQuery1] = useState('');
  const [highlightedText, setHighlightedText] = useState('');
  const [highlightedParagraphs1, setHighlightedParagraphs1] = useState([]);
  const textContent1 = [
    `SafeNet Women : Cybersecurity Empowerment Program`,
    `Bright Eyes, Healthy Smiles : Community Health Camps`,
    `Tiny Teeth, Big Care : Door-to-Door Dental Check Up`,
    `Waste to Feed : Harvesting Expired Goods for Nutritional Cattle Feed`,
    `Assessment of Potential Lead-Contaminated Sites in Tamil Nadu`,
    `Rapid Marketplace Screening (RMS) : An Overview of Our Market Surveys`,
    `Health Camps in Erode and Coimbatore : A Wellness Initiative`,
    `Tracing Lead Sources : Health and Home Assessments in Erode and Coimbatore`,
    `Beach Clean : Unite for a Tidy Shore`,
    ` Michaung Cyclone Relief Work`,

    `The complex technological landscape we navigate has numerous vulnerabilities that demand attention. Cyberattacks and cybercrimes have become integral aspects of our everyday lives, and the field of cybersecurity has emerged as a significant development, offering solutions to these issues.\n\n As we explored this issue further, we recognized another essential change. Historically, the tech industry has been predominantly represented by men, with limited inclusion of women. Questioning this norm, we aspired to foster inclusivity in the world of machine learning, aiming to bring unity to this field. Women have also faced significant challenges in the realm of cybersecurity, and in response, we've strived to educate and empower them, equipping them with the knowledge to navigate these challenges effectively.\n\n Cyber Shiksha is a prominent program initiated by Trisakha Foundation in collaboration with the Data Security Council of India (DSCI) and Microsoft. The program's motto is to provide graduate women from Tier-II, Tier-III cities, and rural areas with a comprehensive understanding of cybersecurity through online courses. Each batch comprises 25 women candidates who undergo a rigorous selection, screening, and training process in Artificial Intelligence and Machine Learning. The program serves as a thoughtful remedy to bring diverse perspectives, creative solutions, address gender-based vulnerabilities, and contribute to the increasing need for cybersecurity experts in today’s world.`,
    `An eye health program conducted by Trisakha Foundation was held on April 10, 2023, at an elderly care home in Washermanpet, Chennai, in collaboration with Uma Eye Clinic. Our team, accompanied by eye specialists, conducted assessments for refractive errors and eye ailments for senior citizens. Concurrently, a dental camp led by Dr. Soumiya Mohan, our renowned dentist, facilitated oral and periodontal health check-ups. As a gesture of care, our doctors provided free guidance on basic oral hygiene and proper brushing techniques to the elderly residents.`,
    `On behalf of Trisakha Foundation, a dental check-up for children under six was conducted in Rangapuram, Vellore, on October 16, 2022. Led by our skilled dentist, Dr. Soumia Mohan, and a team of six members, the camp aimed to address dental hygiene issues in young children. During the check-up, common issues such as tooth decay and improper brushing were identified as factors affecting dental health. It was disheartening to observe that every child had at least one decayed tooth.\n\nEvery child and their parents received education on the importance of dental hygiene and its consequences. Our team provided essential guidance, including brushing teeth twice a day, especially after consuming sugary foods and drinks, consulting a dentist twice a year, and ensuring overall oral hygiene. To address the issue, we distributed individual dental kits and made the brushing process enjoyable for the children.`,
    `SS Waste link Sustainability Services PVT, a dedicated animal feed manufacturing company, has joined forces with us to revolutionize the process of converting food waste into nutritious animal feed. In this collaborative initiative, our team diligently collected expired food products from numerous stores across Tamil Nadu and Andhra Pradesh and sent them to Waste Link's specialized unit for processing. The result of this eco-friendly process is Eco-Feed, a high-quality animal feed prepared from expired food products. We proudly present this as a cost-effective and sustainable solution to food brands, eliminating greenhouse gas emissions associated with traditional waste disposal.`,
    `As part of our collaboration with the Bharat Pure Earth Foundation (BPEF), we actively participated in the Toxic Site Identification Program (TSIP), the largest online global inventory of polluted places Our team members conducted on-site visits to contaminated areas, engaging with stakeholders, conducting interviews, and collecting samples. Through these efforts, approximately 30 sites heavily contaminated with lead were identified and assessed across various districts in Tamil Nadu, including Chennai, Tiruvallur, Kancheepuram, Ranipet, Chengalpattu, Vellore, Dharmapuri, Salem, Erode, Coimbatore, Tirupur, Dindigul, Theni, Madurai, Pudukkottai, and Tiruchirapalli. The detailed findings from these examinations have been documented and uploaded to the TSIP online database, accessible through www.contaminatedsites.org.`,
    `Trisakha Foundation joined hands with Bharat Pure Earth Foundation (BPEF) to conduct an extensive analysis of lead levels in consumer products across 20 cities in Tamil Nadu. Marketplace surveys (RMS) were conducted following a predefined protocol, which included selecting markets, conducting interviews, and collecting samples of products such as spices, major starch, cosmetics, paints, plastic toys, metallic and earthen cookware, etc. The goal was to trace the source of lead contamination.\n\nAround 600 samples were collected and subjected to testing. The survey findings highlighted local paints, plastic toys, Indalium, brass, and ceramic cookware as the primary sources of exposure.`,
    `In association with Equitas Small Finance Bank Ltd., health camps were conducted in the villages of Ezuthingalpatti in Erode and Selakkarichal in Coimbatore. Our trained specialists systematically performed tests including Blood pressure, Diabetes, Dental check-ups, Eye examinations, and overall body check-ups.`,
    `The earlier program we conducted for Toxic Site Identification revealed high levels of lead in seven villages in Erode and Coimbatore. In response to this issue, we assessed lactating mothers and approximately 120 children for lead pollution exposure. Home-based assessments and surveys were carried out in households located near industrial areas. To facilitate this survey, we collected samples of spices, paint, cosmetics, dust, toys, and water used by the residents. The success of the project is attributed to the collaboration with Bharat Pure Earth Foundation (BPEF) and the National Referral Centre for Lead Projects in India, Bengaluru. We also received valuable support from Equitas Small Finance Bank Ltd., which played a crucial role in mobilizing the community and providing necessary assistance.`,
    `In collaboration with the Indian Maritime Foundation and Bharat Petroleum Corporation Ltd., we conducted a beach clean-up drive in Besant Nagar, near the Ashtalakshmi Temple, on July 15, 2023. The program was inaugurated by Sandeep Rai Rathore, the Police Commissioner of Chennai. Members of the Greater Chennai City Police also actively participated in the drive, promoting their 'Drug-Free Chennai' campaign. Approximately 2000 kgs of waste were collected, sorted by category, and disposed of appropriately.`,
    `Cyclone Michaung drastically affected Chennai which is India's fourth largest city and a crucial business center. The cyclone hit northern Tamil Nadu, Chennai and the surrounding districts during the 1st week of December,2023 and disrupted the livelihoods of so many people. Team Trisakha  jumped on the field and contributed to flood relief by distributing food packets and other essentials in the affected areas of Taramani and Velachery.`,
    `What We Have Tackled !`,
    `A Few Remarkable Milestones We Have Accomplished`,
    `Home`,
    `About Us`,
    `Initiatives`,
    `Blogs`,
    `Training`,
  ];

  const highlightSearch = () => {
    const text = query1;
    if (!text) {
      setHighlightedParagraphs1(textContent1); // Reset if query is empty
      return;
    }

    const queryRegex = new RegExp(`(${text})`, 'gi');
    const highlighted = textContent1.map(paragraph =>
      paragraph
        .replace(/(<span class="highlight">|<\/span>)/gi, '') // Remove existing highlights
        .replace(queryRegex, '<span class="highlight">$1</span>')
    );

    setHighlightedParagraphs1(highlighted);
  };

  useEffect(() => {
    highlightSearch();
  }, [query1]);

  const location = useLocation();
  const [activeIndex, setActiveIndex] = React.useState(0); // Default to the first slide

  useEffect(() => {
    if (location.state && location.state.targetSlide) {
      switch (location.state.targetSlide) {
        case '1':
          setActiveIndex(1); // Set this to the index of the slide that matches "What We Do"
          break;
        // Add more cases if you have other slides
        default:
          setActiveIndex(0); // Default to the first slide
      }
    }
  }, [location]);


  return (
    <>
      <DonateModal open={open} handleClose={handleClose} />
      <header className="">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Navbar /> */}
          <nav className="navbar navbar-expand-lg ">
            {/* <a className="navbar-brand" href="#">Trisakha</a> */}
            {/* <img src={logo} alt="Logo" className="navbar-brand" width={70} />
        <img src={logo} alt="Logo" className="logo" /> */}
            <img onClick={goToHome} src={logo111} alt="Logo" className="navbar-brand" width={70} />
            <img onClick={goToHome} src={logo111} alt="Logo" className="logos" />
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleNavbar}
              aria-controls="navbarSupportedContent"
              aria-expanded={isCollapsed}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto nav_ul">
                <li className="nav-item active">
                  <a className="nav-link"  ><NavLink to="/" exact active ClassName="active" className="nav-link">
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[22] }} />
                  </NavLink><span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link"  ><NavLink to="/about" active ClassName="active" className="nav-link">
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[23] }} />
                  </NavLink></a>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#"><NavLink to="/initiatives" active ClassName="active" className="nav-link"> */}
                  <a className="nav-link"  ><NavLink to="/initiatives" active ClassName="active" className="nav-link">
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[24] }} />
                  </NavLink></a>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#"><NavLink to="/blogs" active ClassName="active" className="nav-link"> */}
                  <a className="nav-link"  ><NavLink to="/blogs" active ClassName="active" className="nav-link">
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[25] }} />
                  </NavLink></a>
                </li>
                <li className="nav-item" >
                  <a className="nav-link"  > <NavLink onClick={(e) => e.preventDefault()} to="/training" active ClassName="active" className="nav-link">
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[26] }} />
                  </NavLink></a>
                </li>
                <form onSubmit={(e) => e.preventDefault()}>

                </form>
                <form className="form-inline my-2 my-lg-0">
                  <div className='searchBar_cls' style={{ position: "relative" }}>
                    <input className="form-control mr-sm-2 searchBar" value={query1}
                      onChange={(e) => setQuery1(e.target.value)} name="query1" type="text" placeholder="Search" aria-label="Search" style={{ paddingLeft: "30px" }} />
                    <SearchIcon style={{ position: "absolute", right: "12.8rem", bottom: "5px", color: 'white' }} />
                  </div>
                </form>
              </ul>
            </div>
          </nav>

          <div
            className="header-top-right"
            style={{ paddingTop: "1rem", marginRight: "2rem" }}
          >
            <div className="social-icons">
              <IconButton className="contact-button linkedin-icon">
                <Phone />
              </IconButton>
              <IconButton className="contact-button">
                <Email />
              </IconButton>
            </div>
            <div className="social-icons2">
              <IconButton className="">
                {/* <Facebook /> */}
                <img src={fb_icon} width={45} style={{ marginRight: "-2px" }} />
              </IconButton>
              <IconButton className="">
                <img src={linkedin_icon} width={45} style={{ marginRight: "-5px" }} />

                {/* <LinkedInIcon /> */}
                {/* <LinkedIn /> */}
              </IconButton>
            </div>
          </div>
        </div>

        <div className="contentAndLogoContainer2">
          <div className="header-banner22">
            <h2 className="Initiatives_head">Initiatives</h2>
            <div className="buttons2">
              <button className="volunteer1">VOLUNTEER</button>
              <button className="donate1" onClick={handleOpen}>DONATE</button>
            </div>
          </div>
          <div className="subContentAndLogoContainer1">
            <img
              src={MainSideLogo}
              alt="MainSideLogo"
              className="MainSideLogo1"
            />
          </div>
        </div>
      </header>
      {/* <div style={{height:"100%"}}> */}
      <div style={{ backgroundColor: "#0c9d6d" }}>

        <div className="main-container">
        <div style={{display:"flex",flexDirection:'column'}}> {
                data.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "2rem",
                        margin: "1rem 2rem",
                        // color:"#0c9d6d",
                        // border:"2px solid white",
                        backgroundColor:"#ebffdf",
                        padding:"1rem",
                        borderRadius:"10px",
                        boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        flexDirection: index % 2 === 0 ? "row" : "row-reverse", // Toggle the order
                      }}
                    >
                      <img src={item.image} width={500} />
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <p>{item.heading}</p>
                        <p style={{textAlign:"justify"}}>{item.paragraph}</p>
                      </div>
                    </div>
                  ))
                }                  
                    </div>

          <div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
          </div>
          
        </div>
        {/* </div> */}
        <br />
        <br />
        <br />
        <br />
        {/* <br/> */}

        <div className='contact_form_component'>
          <FormComponent />
        </div>

        <h1 className='div6_h contact_form' align='center' style={{ paddingBottom: "3rem", paddingTop: "2rem", backgroundColor: "white", margin: "0px" }}>SCHEDULE A MEETING</h1>
        <div className='div_6 contact_form' style={{ backgroundColor: "" }}>
          <div style={{ paddingTop: "0rem", background: "linear-gradient(180deg, rgba(255,255,255,1) 27%, rgba(255,255,255,0) 100%)" }}>
            <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", }}>
              <div className="col-lg-7">
                <div className="php-email-form" data-aos="fade-up" data-aos-delay="200" >
                  <div className="row gy-4" >
                    <div style={{ display: "flex", gap: "" }}>
                      <div className="row gy-4" style={{ margin: "0px" }}>
                        <div className="col-md-5">
                          <label style={{ fontWeisssght: "bold", color: "#141C48" }} for="name-field" className="pb-2">First Name</label>
                          <input
                            required
                            value={formData.first_name}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" name="first_name" id="name-field" className="form-control" />
                        </div>

                        <div className="col-md-5">
                          <label style={{ fontWeisssght: "bold", color: "#141C48" }} for="lastname-field" className="pb-2">Last Name</label>
                          <input
                            required
                            value={formData.last_name}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" className="form-control" name="last_name" id="email-field" />
                        </div>
                        <div className="col-md-10">
                          <label style={{ fontWeigsssht: "bold", color: "#141C48" }} for="phonenumber-field" className="pb-2">Phone Number</label>
                          <input
                            required
                            value={formData.phone_number}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="number" className="form-control" name="phone_number" id="email-field" />
                        </div>
                        <div className="col-md-10" style={{ marginTop: "10px" }}>
                          <label style={{ fontWessight: "bold", color: "#141C48" }} for="email-field" className="pb-2">Email ID</label>
                          <input
                            required
                            value={formData.email}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="email" className="form-control" name="email" id="email-field" />
                        </div>
                        {/* </div> */}
                        {/* <div className="row gy-4" > */}
                        <div className="col-md-10" style={{ marginTop: "0px" }}>
                          <label style={{ fontWssseight: "bold", color: "#141C48" }} for="subject-field" className="pb-2">Organization Name</label>
                          <input
                            required
                            value={formData.org_name}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" className="form-control" name="org_name" id="subject-field" />
                        </div>
                        <div className="col-md-10" style={{ marginTop: "0px" }}>
                          <label style={{ fontWssseight: "bold", color: "#141C48" }} for="message-field" className="pb-2">Message</label>
                          <textarea
                            value={formData.message}
                            onChange={handleChange}
                            style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} className="form-control" name="message" rows="5" id="message-field"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "center", alignItems: "center", padding: "2rem" }}>
                    <button
                      onClick={handleSubmit}
                      style={{ border: "none", backgroundColor: "#304145", height: "40px", width: "100px", borderRadius: "5px", color: "white", marginBottom: "2rem" }}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
