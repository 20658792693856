import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../css/Navbar.css";
import logo from "../assets/trisakha_logo.png";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Navbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [matches, setMatches] = useState([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);

  console.log("searchQuery", searchQuery);
  console.log("matches", matches);
  console.log("currentMatchIndex", currentMatchIndex);

  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const goToHome = () => {
    navigate("/");
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
};
  useEffect(() => {
    if (searchQuery) {
      const regex = new RegExp(searchQuery, "gi");
      const pageContent = document.querySelector(".page-content");

      if (pageContent) {
        const bodyText = pageContent.innerHTML;
        const newMatches = [...bodyText.matchAll(regex)];
        setMatches(newMatches);

        console.log("searchQuery:", searchQuery);
        console.log("matches:", newMatches);

        highlightMatches(newMatches);
        setCurrentMatchIndex(0); // Set the index to the first match
      } else {
        console.error("Error: .page-content element not found on the page.");
      }
    } else {
      removeHighlights();
      setMatches([]);
    }
  }, [searchQuery]);

  const highlightMatches = (matches) => {
    removeHighlights(); // Clear previous highlights

    const pageContent = document.querySelector(".page-content");
    if (!pageContent) return;

    let innerHTML = pageContent.innerHTML;
    matches.forEach((match) => {
      const highlight = `<span class="highlight">${match[0]}</span>`;
      innerHTML = innerHTML.replace(match[0], highlight);
    });
    pageContent.innerHTML = innerHTML;
  };

  const removeHighlights = () => {
    const pageContent = document.querySelector(".page-content");
    if (!pageContent) return;

    pageContent.innerHTML = pageContent.innerHTML.replace(
      /<span class="highlight">(.*?)<\/span>/g,
      "$1"
    );
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg ">
        {/* <a className="navbar-brand" href="#">Trisakha</a> */}
        {/* <img src={logo} alt="Logo" className="navbar-brand" width={70} />
        <img src={logo} alt="Logo" className="logo" /> */}
        <img
          onClick={goToHome}
          src={logo}
          alt="Logo"
          className="navbar-brand"
          width={70}
        />
        <img onClick={goToHome} src={logo} alt="Logo" className="logos" />
        {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarSupportedContent"
          aria-expanded={isCollapsed}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${isCollapsed ? "" : "show"}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto nav_ul">
            <li className="nav-item active">
              <a className="nav-link" href="#">
                <NavLink
                  to="/"
                  exact
                  active
                  ClassName="active"
                  className="nav-link"
                >
                  Home
                </NavLink>
                <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                <NavLink
                  to="/about"
                  active
                  ClassName="active"
                  className="nav-link"
                  id="aboutPage"
                >
                  About Us
                </NavLink>
              </a>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#"><NavLink to="/initiatives" active ClassName="active" className="nav-link"> */}
              <a className="nav-link" href="#">
                <NavLink
                  to="/initiatives"
                  active
                  ClassName="active"
                  className="nav-link"
                >
                  Initiatives
                </NavLink>
              </a>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#"><NavLink to="/blogs" active ClassName="active" className="nav-link"> */}
              <a className="nav-link" href="#blogs">
                <NavLink
                  to="/blogs"
                  active
                  ClassName="active"
                  className="nav-link"
                >
                  Blogs
                </NavLink>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#training">
                {" "}
                <NavLink
                  onClick={(e) => e.preventDefault()}
                  to="/training"
                  active
                  ClassName="active"
                  className="nav-link"
                >
                  Training
                </NavLink>
              </a>
            </li>

            <form
              className="form-inline my-2 my-lg-0"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="searchBar_cls" style={{ position: "relative" }}>
                <input
                  className="form-control mr-sm-2 searchBar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  style={{ paddingLeft: "30px" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <SearchIcon
                  style={{
                    position: "absolute",
                    right: "12.8rem",
                    bottom: "5px",
                    color: "white",
                  }}
                />
              </div>
            </form>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
