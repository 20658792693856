import React, { useState, useLayoutEffect, useRef, useCallback, useEffect } from "react";
import { Facebook, LinkedIn, Email, Phone } from "@mui/icons-material";
import { styled, IconButton, InputBase } from "@mui/material";
import MainSideLogo from "../assets/image11.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import fb_icon from "../assets/fb_icon.png";
import linkedin_icon from "../assets/linkedin_icon.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../css/Content.css"; // Import your custom styles
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import data from "../data";
import FormComponent from "./FormComponent";
import Navbar from "./Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DonateModal from "./DonateModal";
import blog1_pic from '../assets/blog1_pic.jpg'
import blog2_pic from '../assets/cyber.png'
import blog3_pic from '../assets/cyber2.png'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import logo111 from "../assets/trisakha_logo.png";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Add icons to the library
library.add(faChevronLeft, faChevronRight);

const CustomArrow = ({ onClickHandler, label }) => (
  <button
    type="button"
    onClick={onClickHandler}
    className={`custom-arrow ${label}`}
    aria-label={label}
  >
    <FontAwesomeIcon icon={label === "prev" ? faChevronLeft : faChevronRight} />
  </button>
);

export default function Blogs() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
};
  const goToHome = () => {
    navigate('/');
};
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselContainerRef = useRef(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    org_name: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.first_name === "" ||
      formData.last_name === "" ||
      formData.phone_number === "" ||
      formData.email === "" ||
      formData.org_name === "" ||
      formData.message === ""
    ) {
      alert("Please Fill All The Fields");
    } else {
      axios
        .post(`https://trisakha.org/upload`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.status);
            toast.success("Our Team Will Contact You Soon!", {
              autoClose: 5000,
            });
            setTimeout(() => {
              window.location.reload()
    
            }, 5000);
          }
        })
        .catch((error) => {
          alert("Some Error Occured Contact Admin");

          console.log(error);
        });
    }
    console.log(formData);
  };

  const updateHeight = useCallback(() => {
    if (carouselContainerRef.current) {
      const activeItem = carouselContainerRef.current.querySelector(
        ".carousel-item.active"
      );
      if (activeItem) {
        const height = activeItem.clientHeight;
        carouselContainerRef.current.style.height = `${height}px`;
      }
    }
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      updateHeight();
    };

    window.addEventListener("resize", handleResize);
    updateHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateHeight]);

  const handleImageLoad = () => {
    updateHeight();
  };

  const handleChange2 = (index) => {
    setCurrentIndex(index);
    updateHeight(); // Ensure height update on slide change
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [query1, setQuery1] = useState('');
    const [highlightedText, setHighlightedText] = useState('');
    const [highlightedParagraphs1, setHighlightedParagraphs1] = useState([]);
    const textContent1 = [
      `April 8, 2024`,
      `Gender-Balance: A Critical Need to Make Cyber-security Space More Dynamic and Robust.`,
      `Promila Malik, Trustee, Trisakha Foundation`,
      `Women represent half of the world’s population, and so half of its potential. However, they make up only a fraction (1/5th) of the total workforce in STEM (science, technology, engineering, and math), including AI & cybersecurity. Nuances in cybersecurity are best understood and strategized with different perspectives of diverse sets of professionals. With this big a gender imbalance, one can expect the huge contribution potentially being lost by the pool of cybersecurity experts in terms of spotting red flags, developing a proactive strategy, and checking the intruders...`,
      `In the ever-evolving technology landscape, the complex Cyber-attack tactics employed by the adversaries on the corporate & government networks have been rising, hence a challenge for the businesses to secure themselves. Today, to safeguard employees and customers from losing their confidential data, an all-encompassing business strategy is required including the vision and knowledge to track the potential adversaries beforehand. Any effort to strike an impactful gender-balance in the field of cybersecurity is indeed a critical need. Here are the reasons in support:`,
      `Diverse perspectives: Gender diversity brings a variety of perspectives and approaches to the field. A range of viewpoints can help identify vulnerabilities and threats that might be overlooked in a homogeneous workforce.`,
      `More creative and innovative solutions: A diverse team with diverse backgrounds and experiences is more likely to come up with creative and innovative solutions and insights to address cybersecurity challenges. Studies have shown that mixed-gender teams tend to make better decisions and work together more effectively, another desirable cyber-skill when dealing with cyber threats and incidents.`,
      `Addressing gender-based vulnerabilities: Many cybersecurity threats target individuals based on gender, such as cyberbullying, harassment, and identity theft. Having women in the field can provide a better understanding of these issues and bring in better perspectives on protection measures.`,
      `A broader talent pool: More women in the field imply a larger talent pool that can address the existing shortage of cybersecurity professionals. The growing demand for skilled cybersecurity experts will also be met.`,
      `Cybershikshaa program of the Data Security Council of India (DSCI) funded by Microsoft (MS) is one such effort to have recognised the critical need and role of including more Indian women, enhancing/building their capabilities before adding them into the workforce. Trisakha Foundation has joined hands with DSCI & MS in:`,
      `Initiatives by Trisakha Foundation`,
      `Designing and developing the syllabus and study course material on AI/ML`,
      `Identifying the potential women workforce from Tier-2 and Tier-3 cities facing economic challenges to avail the opportunities`,
      `Providing them the online platforms to learn and equip themselves`,
      `Encouraging and assisting them to pursue careers in cybersecurity for a well-paying and future-proof industry
    With strengthened cybersecurity capabilities, Trisakha Foundation strongly believes that women experts will tend to bring in:`,
      `Novel, creative, and diverse perspectives to problem-solving`,
      `Better risk management`,
      `Improved business performance`,
      `Strengthened economic and social development indicators for the nation`,
      `A recent report by the National Association of Software and Services Companies (NASSCOM) highlights India’s leadership role in the AI domain with the first rank in skill-penetration and  talent-concentration among all OECD and G20 countries, measuring 3.09. This indicator shows that compared to other countries, Indian tech talent is three -times more likely to possess AI skills.
India despite having a substantial tech-savvy workforce is facing a demand-supply gap of the AI experts. Encouraging, empowering and nurturing women, thus, becomes an absolute imperative to bridge this gap and driving India to leave imprints on the global scale.`,
      
    `Cyber Awareness: Safeguarding Our Digital Future`,
    ` Ankit Tiwari, Solution Engineer`,
    `In today’s hyper-connected world, our lives are increasingly intertwined with digital technology. From online banking to social media, the digital realm offers countless conveniences, but it also presents significant risks. Cybercrime or cyber fraud is on the rise, and anyone who uses the internet is a potential target. This makes cyber awareness not just a priority, but a necessity for everyone.<br/><br/><b>“Being aware of the dangers of interacting online, checking email and browsing the web are all components of cyber awareness”.</b><br/> `,
    `Understanding Cyber Threats`,
    `Cyber threats come in many forms, ranging from phishing scams and malware to identity theft and ransomware attacks. These threats exploit vulnerabilities in our systems, networks, and even our own user-behaviour. Understanding the various types of cyber threats is the first step in defending against them.`,
    `<b>Phishing:</b>  one of the most common cyber threats, where attackers disguise themselves as legitimate entities to steal sensitive information like usernames, passwords, and credit card details. Phishing attacks often occur through emails, messages, or fake websites that appear authentic.`,
    `<b>Malware:</b> Malicious software, or malware, is designed to damage or disable computers, steal data, or spy on users. Types of malware include viruses, worms, Trojan horses, and ransomware. Once malware infects a system, it can cause extensive damage, leading to data loss, financial theft, and compromised security.`,
    `<b>Ransomware:</b> its a type of malware that encrypts the victim’s data and demands a ransom to restore access. Quite a lucrative business it has become for cybercriminals, targeting individuals, businesses, and government agencies.`,
    `<b>Identity Theft:</b> Cybercriminals use stolen personal information to impersonate victims, committing fraud or other crimes in their name. Identity theft can have devastating consequences, including financial loss, damaged credit, and legal complications.`,
    `<b>Social Engineering:</b> This technique involves manipulating individuals into divulging confidential information or performing actions that compromise security. Social engineering can occur through various channels, including phone calls, emails, or in-person interactions, and it preys on human psychology rather than technical vulnerabilities.`,
    `The Importance of Cyber Awareness`,
    `Cyber awareness involves understanding the risks and knowing how to protect oneself in the digital landscape. It is essential for everyone, from individuals to large organisations, to be aware of the threats and take proactive steps to mitigate them.`,
    `<b>Personal Responsibility:</b> Every individual has a role to play in maintaining cyber security. This includes using strong, unique passwords for different accounts, enabling multi-factor authentication (MFA), and being cautious when clicking on links or downloading attachments.`,
    `<b>Education and Training:</b> Continuous education on the latest cyber threats and best practices is crucial. For organisations, regular training sessions can help employees recognize and respond to potential threats, reducing the risk of a successful attack.`,
    
    `<b>Secure Practices:</b> Implementing secure practices, such as regularly updating software and using antivirus programs, can help protect against vulnerabilities. It’s also important to back up data regularly to ensure that it can be recovered in case of a cyber attack.`,
    `<b>Community Awareness:</b> Cyber threats don’t just affect individuals—they can impact entire communities. Initiatives like community workshops, school programs, and public awareness campaigns are essential in spreading knowledge and promoting safe online behaviour.`,
    `<b>Government and Corporate Responsibility:</b> Governments and corporations have a duty to protect the public and their customers by enforcing stringent cyber security measures. This includes developing and implementing policies, investing in advanced security technologies, and responding swiftly to cyber incidents.`,
    `The Future of Cybersecurity`,
    `As technology continues to evolve, so do cyber threats. The future of cybersecurity lies in staying ahead of these threats through innovation, education, and vigilance. Artificial Intelligence (AI) and Machine Learning (ML) are already being used to detect and respond to threats more effectively, but these technologies must be complemented by human awareness and action.`,
    `Cyber awareness is not just about protecting data & information—it’s about protecting our way of life. By making cyber awareness a priority, we can create a safer digital environment for everyone, ensuring that the benefits of the digital age are enjoyed without fear of exploitation or harm.`,
    `Home`,
    `About Us`,
    `Initiatives`,
    `Blogs`,
    `Training`,
    

      
    ];
  
    const highlightSearch = () => {
      const text = query1;
      if (!text) {
        setHighlightedParagraphs1(textContent1); // Reset if query is empty
        return;
      }
  
      const queryRegex = new RegExp(`(${text})`, 'gi');
      const highlighted = textContent1.map(paragraph =>
        paragraph
          .replace(/(<span class="highlight">|<\/span>)/gi, '') // Remove existing highlights
          .replace(queryRegex, '<span class="highlight">$1</span>')
      );
  
      setHighlightedParagraphs1(highlighted);
    };
  
    useEffect(() => {
      highlightSearch();
    }, [query1]);
  return (
    <>
      <DonateModal open={open} handleClose={handleClose} />
      <header className="">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* <Navbar /> */}
        <nav className="navbar navbar-expand-lg ">
        {/* <a className="navbar-brand" href="#">Trisakha</a> */}
        {/* <img src={logo} alt="Logo" className="navbar-brand" width={70} />
        <img src={logo} alt="Logo" className="logo" /> */}
                <img onClick={goToHome} src={logo111} alt="Logo" className="navbar-brand" width={70} />
                <img onClick={goToHome} src={logo111} alt="Logo" className="logos" />
        {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> */}
        <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
                aria-controls="navbarSupportedContent"
                aria-expanded={isCollapsed}
                aria-label="Toggle navigation"
            >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto nav_ul">
                <li className="nav-item active">
                  <a className="nav-link"  ><NavLink to="/" exact active ClassName="active" className="nav-link">
                    {/* <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[22] }} /> */}
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[highlightedParagraphs1.length - 5] }} />

                  </NavLink><span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link"  ><NavLink to="/about" active ClassName="active" className="nav-link">
                    {/* <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[23] }} /> */}
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[highlightedParagraphs1.length - 4] }} />

                  </NavLink></a>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#"><NavLink to="/initiatives" active ClassName="active" className="nav-link"> */}
                  <a className="nav-link"  ><NavLink to="/initiatives" active ClassName="active" className="nav-link">
                    {/* <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[24] }} /> */}
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[highlightedParagraphs1.length - 3] }} />

                  </NavLink></a>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#"><NavLink to="/blogs" active ClassName="active" className="nav-link"> */}
                  <a className="nav-link"  ><NavLink to="/blogs" active ClassName="active" className="nav-link">
                    {/* <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[25] }} /> */}
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[highlightedParagraphs1.length - 2] }} />

                  </NavLink></a>
                </li>
                <li className="nav-item" >
                  <a className="nav-link"  > <NavLink onClick={(e) => e.preventDefault()} to="/training" active ClassName="active" className="nav-link">
                    {/* <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[26] }} /> */}
                    <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[highlightedParagraphs1.length - 1] }} />

                  </NavLink></a>
                </li>
                <form onSubmit={(e) => e.preventDefault()}>
          
        </form>
        <form className="form-inline my-2 my-lg-0">
              <div className='searchBar_cls' style={{ position: "relative" }}>
                <input className="form-control mr-sm-2 searchBar"  value={query1}
            onChange={(e) => setQuery1(e.target.value)} name="query1" type="text" placeholder="Search" aria-label="Search" style={{ paddingLeft: "30px" }}  />
                <SearchIcon id='search_icon' />

              </div>
            </form>
          </ul>
        </div>
      </nav>

          <div
            className="header-top-right"
            style={{ paddingTop: "1rem", marginRight: "2rem" }}
          >
            <div className="social-icons">
              <IconButton className="contact-button linkedin-icon">
                <Phone />
              </IconButton>
              <IconButton className="contact-button">
                <Email />
              </IconButton>
            </div>
            <div className="social-icons2">
              <IconButton className="">
                {/* <Facebook /> */}
                <img src={fb_icon} width={45} style={{ marginRight: "-2px" }} />
              </IconButton>
              <IconButton className="">
                <img
                  src={linkedin_icon}
                  width={45}
                  style={{ marginRight: "-5px" }}
                />

                {/* <LinkedInIcon /> */}
                {/* <LinkedIn /> */}
              </IconButton>
            </div>
          </div>
        </div>

        <div className="contentAndLogoContainer2">
          <div className="header-banner2">
            <h2 className="about_us">Blogs</h2>
            <div className="buttons2">
              <button className="volunteer1">VOLUNTEER</button>
              <button className="donate1" onClick={handleOpen}>
                DONATE
              </button>
            </div>
          </div>
          <div className="subContentAndLogoContainer1">
            <img
              src={MainSideLogo}
              alt="MainSideLogo"
              className="MainSideLogo1"
            />
          </div>
        </div>
      </header>

      {/* <div style={{height:"100%"}}> */}
    <div style={{backgroundColor:"#0c9d6d"}}>
    <div style={{ padding:"5rem 5rem 3rem 5rem",width:"100%", backgroundColor:"#0c9d6d", display:"flex", flexDirection: "column", gap:"0rem"}} className="mainDiv">
    <div style={{display:"flex", gap:"2rem"}} className="divContentMain">
        <div className="imgAndSideContentDiv">
        <a style={{color:"white",fontSize:"16px",fontWeight:"bold"}} href="https://www.linkedin.com/pulse/gender-balance-critical-need-make-cyber-security-space-promila-malik-6gccc/?trackingId=4M%2BxdwvuTieo5deA01uvlw%3D%3D" target="_blank" rel="noopener noreferrer">
        <img src={blog1_pic} width="600px" style={{borderRadius:"5px"}}/></a>
        </div>
        <div style={{color:"white", flex: 1}} className="introContent">
        <p style={{color:"#DEE2E6"}}><InsertInvitationIcon /> <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[0] } }/></p>
            {/* <h2 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[1] } }/> */}
            <h2><a style={{
                color: "white",fontFamily: "system-ui,-apple-system",textDecoration: "none", fontWeight: "normal"}} href="https://www.linkedin.com/pulse/gender-balance-critical-need-make-cyber-security-space-promila-malik-6gccc/?trackingId=4M%2BxdwvuTieo5deA01uvlw%3D%3D" target="_blank" rel="noopener noreferrer"><h2 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[1] }} /></a></h2>
            <span style={{color:"#DEE2E6"}}>< PersonOutlineIcon /> <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[2] } }/> </span><br/><br/>
            <p className="introContent_para2" style={{fontSize:"16px", textAlign: "justify",lineHeight:"1.9"}} dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[3] } }/>
        </div>
    </div>
    <div style={{color:"white"}} className="middleContentMainDiv">
        <p style={{fontSize:"16px",marginTop:"0.9rem", textAlign: "justify",lineHeight:"1.9"}}>
        <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[4] } }/>
        <div style={{padding:"0rem", color: "white"}}>
    <ul style={{listStyleType: "disc", paddingLeft: "1rem", fontSize: "16px",lineHeight:"1.9"}}>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[5] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[6] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[7] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[8] } }/>
    </ul>
</div>
<sapn dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[9] } }/>
<div style={{paddingTop:"1rem", width:"100%", backgroundColor:"#0c9d6d", color: "white"}} className="initiativeMainDiv">
    <h5 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[10] } }/>
    <ol style={{paddingLeft: "1rem", fontSize: "16px",lineHeight:"1.9"}}>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[11] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[12] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[13] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[14] } }/>
    </ol>

    <ol style={{paddingLeft: "2rem", fontSize: "16px"}}>
    <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[15] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[16] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[17] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[18] } }/>
    </ol>
</div>
<span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[19] } }/> 

        </p>
        <a style={{color:"white",fontSize:"16px",fontWeight:"bold"}} href="https://www.linkedin.com/pulse/gender-balance-critical-need-make-cyber-security-space-promila-malik-6gccc/?trackingId=4M%2BxdwvuTieo5deA01uvlw%3D%3D" target="_blank" rel="noopener noreferrer">
        Read More</a>
    </div>
</div>
<hr style={{margin:"0rem 5rem",border:"2px solid white"}}/>
    <div style={{ padding:"5rem",width:"100%",marginTop:"-2rem" ,backgroundColor:"#0c9d6d", display:"flex", flexDirection: "column", gap:"0rem"}} className="mainDiv">
    <div style={{display:"flex", gap:"2rem"}} className="divContentMain">
        <div className="imgAndSideContentDiv">
        <a style={{color:"white",fontSize:"16px",fontWeight:"bold"}} >
        <img src={blog2_pic} className="blog2_img1" height='450px' width="600px" style={{borderRadius:"5px"}}/></a>
        </div>
        <div style={{color:"white", flex: 1}} className="introContent">
        {/* <p style={{color:"#DEE2E6"}}><InsertInvitationIcon /> <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[0] } }/></p> */}
            {/* <h2 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[1] } }/> */}
            <h2><a style={{
                color: "white",fontFamily: "system-ui,-apple-system",textDecoration: "none", fontWeight: "normal"}} ><h2 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[20] }} /></a></h2>
            <span style={{color:"#DEE2E6"}}>< PersonOutlineIcon /> <span dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[21] } }/> </span><br/><br></br>
            <p className="introContent_para2" style={{fontSize:"16px", textAlign: "justify",lineHeight:"1.8",marginBottom:"2rem"}} dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[22] } }/>
            <b style={{fontSize:"18px"}} dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[23] } }/>
            <p className="introContent_para2" style={{textAlign: "justify",fontSize:"16px",lineHeight:"1.8"}} dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[24] } } />
        </div>
    </div>
    <div style={{color:"white"}} className="middleContentMainDiv">
        <p style={{fontSize:"16px",marginTop:"0.9rem", textAlign: "justify",lineHeight:"1.9"}}>
       

        <div style={{padding:"0rem", color: "white"}}>
    <ul style={{listStyleType: "disc", paddingLeft: "1rem", fontSize: "16px",lineHeight:"1.9"}}>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[25] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[26] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[27] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[28] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[29] } }/>
    </ul>
</div>
<div style={{paddingTop:"1rem", width:"100%", backgroundColor:"#0c9d6d", color: "white"}} className="initiativeMainDiv">
    <h5 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[30] } }/>
    <p dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[31] } }/>
    <ol style={{paddingLeft: "1rem", fontSize: "16px",lineHeight:"1.9"}}>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[32] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[33] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[34] } }/>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[35] } }/>
        {/* <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[36] } }/> */}
    </ol>
    <div style={{display:"flex", gap:"2rem",justifyContent:"center",marginLeft:"0.1rem"}} className="divContentMain">
    
        <div style={{color:"white", flex: 1}} className="introContent">
            <ol style={{paddingLeft: "1rem", fontSize: "16px",lineHeight:"1.9"}} start={5}>
        <li dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[36] } }/>
        </ol>
            <h2><a style={{color: "white",fontFamily: "system-ui,-apple-system",textDecoration: "none", fontWeight: "normal"}} ><h2 dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[37] }} /></a></h2>
            <p className="introContent_para2" style={{fontSize:"16px", textAlign: "justify",lineHeight:"1.9"}} dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[38] } }/>
            <p className="introContent_para2" style={{fontSize:"16px", textAlign: "justify",lineHeight:"1.9"}} dangerouslySetInnerHTML={{ __html: highlightedParagraphs1[39] } }/>
        </div>
        <div className="imgAndSideContentDiv" style={{marginTop:"-1.7rem"}}>
        <a style={{color:"white",fontSize:"16px",fontWeight:"bold"}} >
        <img src={blog3_pic} className="blog2_img1" height='400px' width="450px" style={{borderRadius:"5px"}}/></a>
        </div>
    </div>
   
</div>

        </p>
    </div>
</div>

      
      {/* </div> */}
      <br/>
      <br/>
      <br/>
      <br/>
      {/* <br/> */}
      
      <div className='contact_form_component'>
                <FormComponent />
            </div>
          
      <h1 className='div6_h contact_form' align='center' style={{ paddingBottom: "3rem", paddingTop: "2rem", backgroundColor: "white", margin: "0px" }}>SCHEDULE A MEETING</h1>
            <div className='div_6 contact_form' style={{ backgroundColor: "" }}>
                <div style={{ paddingTop: "0rem", background: "linear-gradient(180deg, rgba(255,255,255,1) 27%, rgba(255,255,255,0) 100%)" }}>
                    <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", }}>
                        <div className="col-lg-7">
                            <div className="php-email-form" data-aos="fade-up" data-aos-delay="200" >
                                <div className="row gy-4" >
                                    <div style={{ display: "flex", gap: "" }}>
                                        <div className="row gy-4" style={{ margin: "0px" }}>
                                            <div className="col-md-5">
                                                <label style={{ fontWeisssght: "bold", color: "#141C48" }} for="name-field" className="pb-2">First Name</label>
                                                <input
                                                    required
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }} type="text" name="first_name" id="name-field" className="form-control" />
                                            </div>

                        <div className="col-md-5">
                          <label
                            style={{ fontWeisssght: "bold", color: "#141C48" }}
                            for="lastname-field"
                            className="pb-2"
                          >
                            Last Name
                          </label>
                          <input
                            required
                            value={formData.last_name}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "rgb(245, 245, 245,0.5)",
                              border: "1px solid black",
                            }}
                            type="text"
                            className="form-control"
                            name="last_name"
                            id="email-field"
                          />
                        </div>
                        <div className="col-md-10">
                          <label
                            style={{ fontWeigsssht: "bold", color: "#141C48" }}
                            for="phonenumber-field"
                            className="pb-2"
                          >
                            Phone Number
                          </label>
                          <input
                            required
                            value={formData.phone_number}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "rgb(245, 245, 245,0.5)",
                              border: "1px solid black",
                            }}
                            type="number"
                            className="form-control"
                            name="phone_number"
                            id="email-field"
                          />
                        </div>
                        <div
                          className="col-md-10"
                          style={{ marginTop: "10px" }}
                        >
                          <label
                            style={{ fontWessight: "bold", color: "#141C48" }}
                            for="email-field"
                            className="pb-2"
                          >
                            Email ID
                          </label>
                          <input
                            required
                            value={formData.email}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "rgb(245, 245, 245,0.5)",
                              border: "1px solid black",
                            }}
                            type="email"
                            className="form-control"
                            name="email"
                            id="email-field"
                          />
                        </div>
                        {/* </div> */}
                        {/* <div className="row gy-4" > */}
                        <div className="col-md-10" style={{ marginTop: "0px" }}>
                          <label
                            style={{ fontWssseight: "bold", color: "#141C48" }}
                            for="subject-field"
                            className="pb-2"
                          >
                            Organization Name
                          </label>
                          <input
                            required
                            value={formData.org_name}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "rgb(245, 245, 245,0.5)",
                              border: "1px solid black",
                            }}
                            type="text"
                            className="form-control"
                            name="org_name"
                            id="subject-field"
                          />
                        </div>
                        <div className="col-md-10" style={{ marginTop: "0px" }}>
                          <label
                            style={{ fontWssseight: "bold", color: "#141C48" }}
                            for="message-field"
                            className="pb-2"
                          >
                            Message
                          </label>
                          <textarea
                            value={formData.message}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "rgb(245, 245, 245,0.5)",
                              border: "1px solid black",
                            }}
                            className="form-control"
                            name="message"
                            rows="5"
                            id="message-field"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "80px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "2rem",
                    }}
                  >
                    <button
                      onClick={handleSubmit}
                      style={{
                        border: "none",
                        backgroundColor: "#304145",
                        height: "40px",
                        width: "100px",
                        borderRadius: "5px",
                        color: "white",
                        marginBottom: "2rem",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
