import React, { useState, useEffect, useRef } from "react";
import { Input, InputAdornment } from "@mui/material";
import "../css/DonateModal.css";
import scanner_img from '../assets/scanner_trisakha.jpeg'
import {
  Modal,
  Box,
  TextField,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import successfullImg from "../assets/paymentDoneImg.png";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCat } from "@fortawesome/free-solid-svg-icons";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import donate_img from '../assets/donate_img.png'

const outerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#F1F2EE",
  border: "none",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const innerStyle = {
  bgcolor: "white",
  borderRadius: 5,
  p: 4,
  maxWidth: "1500px",
};

const buttonStyle = {
  margin: "0 5px",
  padding: "5px 10px",
  backgroundColor: "#87BD42",
  color: "white",
  border: "none",
  borderRadius: "3px",
  cursor: "pointer",
};
const divStyle = {

  height: "100%",
  width:"350px",
  // width:"100%",
  backgroundColor: '#f8f9fa', // Light background color
  border: '1px solid #dee2e6', // Light border
  borderRadius: '8px', // Rounded corners
  padding: '20px', // Spacing inside the box
  margin: '0px 0px', // Spacing around the box
  fontFamily: 'Arial, sans-serif', // Clean font
  fontFamily: "roboto",

  color: '#333', // Darker text color
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Subtle shadow
};
const divStyle1 = {
  backgroundColor: '#f8f9fa', // Light background color
  border: '1px solid #dee2e6', // Light border
  borderRadius: '8px', // Rounded corners
  padding: '20px', // Spacing inside the box
  margin: '20px 0', // Spacing around the box
  fontFamily: 'Arial, sans-serif', // Clean font
  color: '#333', // Darker text color
  // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
  display: "flex",
  justifyContent: "center"
};

const DonateModal = ({ open, handleClose }) => {

  const [donationType, setDonationType] = useState("Monthly");
  const [amount, setAmount] = useState("");
  const [customAmount, setCustomAmount] = useState("");
  const [step, setStep] = useState(1);
  const [timeLeft, setTimeLeft] = useState(30);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [upiId, setUpiId] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [wallets, setWallets] = useState("");
  const [cardInput, setCardInput] = useState("");

  useEffect(() => {
    if (step === 3 && timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    if (timeLeft === 0) {
      setStep(4);
    }
  }, [step, timeLeft]);

  const boxRef = useRef(null);

  useEffect(() => {
    if (open && boxRef.current) {
      boxRef.current.classList.add("show");
    } else if (boxRef.current) {
      boxRef.current.classList.remove("show");
    }
  }, [open]);

  const handleCloseModal = (e) => {
    e.stopPropagation();
    handleClose();
    window.location.reload();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleDonationTypeChange = (event, newDonationType) => {
    if (newDonationType !== null) {
      setDonationType(newDonationType);
      setStep(1);
    }
  };

  const handleAmountClick = (value) => {
    setAmount(value);
    setCustomAmount("");
  };

  const handleCustomAmountChange = (event) => {
    setAmount("");
    setCustomAmount(event.target.value);
  };
  const formData = {
    name: name,
    email: email,
    phone: phone,
    Amount: customAmount,
    Date_Time: new Date().toLocaleString()
  };
  const params = {
    donate: 'donate',
  };
  const modalsubmit = (e) => {
    e.preventDefault();
    axios.post(`https://trisakha.org/upload`, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: params
    }).then((response) => {
      if (response.status === 200) {
        // console.log(response.status);

        toast.success("Thank You For Your Support !", {
          autoClose: 5000,
        });
        setTimeout(() => {
          window.location.reload()

        }, 5000);
      }
    }).catch((error) => {
      alert("Some Error Occured Contact Admin")

      console.log(error);
    });

  }

  // ##########################  Form validations start here ########################

  const validateEmail = (email) => {
    // Basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phone) => {
    // Phone number should contain only digits and have a length between 10 and 15
    const phonePattern = /^[0-9]{10,15}$/;
    return phonePattern.test(phone);
  };

  const validateUpiId = (upiId) => {
    const upiPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+$/;
    return upiPattern.test(upiId);
  };

  const validateWallet = (wallets) => {
    // Example wallet validation (this depends on your specific requirements)
    return wallets.trim().length > 0; // Ensure wallet field is not empty
  };

  const parseCardInput = (input) => {
    const [cardNumber, expiryDate, cvv] = input.split(" ");
    return {
      cardNumber: cardNumber || "",
      expiryDate: expiryDate || "",
      cvv: cvv || "",
    };
  };

  const validateCardNumber = (number) => /^[0-9]{16}$/.test(number);
  // const validateExpiryDate = (date) => /^(0[1-9]|1[0-2])\/(2[0-9])$/.test(date);
  // const validateCvv = (cvv) => /^[0-9]{3,4}$/.test(cvv);

  const handleNextStep = async () => {
    const { cardNumber, expiryDate, cvv } = parseCardInput(cardInput);

    if (step === 1) {
      if (!name || name.length < 4) {
        toast.error("Name must be at least 4 characters long.");
        return;
      }
      if (!validateEmail(email)) {
        toast.error("Please enter a valid email address.");
        return;
      }
      if (!validatePhoneNumber(phone)) {
        toast.error("Please enter a valid phone number (minimum 10 digits).");
        return;
      }
      if (!amount && !customAmount) {
        toast.error("Please select a donation amount.");
        return;
      }
      // Trigger confirmation dialog for step 1
      const proceed = await confirmProceed(
        "Step 1:  Verified before proceeding to pay. Do you want to proceed?"
      );
      if (proceed) {
        // Navigate to step 2 if "Yes" is clicked
        setStep(step + 1);
      }
    } else if (step === 2) {
      if (!validateUpiId(upiId)) {
        toast.error("Please enter a valid UPI ID.");
        return;
      }
      if (!validateCardNumber(cardNumber)) {
        toast.error("Please enter a valid card number (16 digits).");
        return;
      }
      //   // if (!validateExpiryDate(expiryDate)) {
      //   //   toast.error("Please enter a valid expiry date (MM/YY).");
      //   //   return;
      //   // }
      //   // if (!validateCvv(cvv)) {
      //   //   toast.error("Please enter a valid CVV (3 or 4 digits).");
      //   //   return;
      //   // }
      if (!validateWallet(wallets)) {
        toast.error("Please provide wallet details.");
        return;
      }
      if (!amount && !customAmount) {
        toast.error("Please select a donation amount.");
        return;
      }
      // Trigger confirmation dialog for step 2
      // Trigger confirmation dialog for step 2
      confirmProceed()
    }

    // Move to the next step
    setStep(step + 1);
  };

  const confirmProceed = (message) => {
    setStep(step + 1);
  };
  // ##########################  Form validations Ends here ########################

  const DonationTypeToggle = ({ currentStep }) => (
    <Box sx={{ padding: "5px", borderRadius: "25px", display: "flex", justifyContent: "center" }}>
      {/* <ToggleButtonGroup
        value={donationType}
        exclusive
        onChange={handleDonationTypeChange}
        aria-label="Donation Type"
        fullWidth
        sx={{
          borderRadius: "20px",
          overflow: "hidden",
          border: "1px solid #ccc",
          height: 35,
          fontWeight: "bold",
          "& .MuiToggleButtonGroup-grouped": {
            margin: "0",
            borderRadius: "20px",
            border: "none",
            "&.Mui-selected": {
              backgroundColor: "#909090",
              color: "white",
              margin: "2px", // Added margin around the active tab, (4sided margin)
              "&:hover": {
                backgroundColor: "#909090", // color stays the same on hover
              },
            },
            "&:not(.Mui-selected)": {
              backgroundColor: "white",
              color: "#909090",
              "&:hover": {
                backgroundColor: "transparent", // Optional hover color for non-selected buttons
              },
            },
          },
        }}
        className="togglebtns"
      >
        <ToggleButton
          value="Monthly"
          sx={{ flex: 1, fontWeight: "bold", textTransform: "capitalize" }}
        >
          Monthly
        </ToggleButton>
        <ToggleButton
          value="One-Time"
          sx={{ flex: 1, textTransform: "capitalize", fontWeight: "bold" }}
        >
          One-Time
        </ToggleButton>
      </ToggleButtonGroup> */}
      <h2>Trisakha Foundation</h2>
    </Box>
  );

  const renderFirstForm = () => (
    <div className="contentMainDiv">
      <div
        style={{
          marginTop: "2rem",
          marginBottom: "-10px",
          fontSize: "12px",
          fontStyle: "italic",
          fontFamily: "Poppins",
          color: "gray",
        }}
        className="smallHeading"
      >
        <FontAwesomeIcon
          icon={faShieldCat}
          style={{ color: "#8CC342", fontSize: "1rem", marginRight: "8px" }}
        />
        Your information is protected
      </div>
      <div
        style={{
          display: "grid",
          gap: "2.5rem",
          gridTemplateColumns: "3fr 1fr",
        }}
        className="contentMainDiv222"
      >
        <div className="inputBoxesDiv">
          <input type="text"
            placeholder="Name"
            style={{ marginBottom: "1rem", width: "100%", height: "50px" }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="inputStyle"
            required
            inputProps={{ minLength: 4 }}
          />
          <input
            style={{ marginBottom: "1rem", width: "100%", height: "50px" }}
            type="email"
            placeholder="Email ID"
            fullWidth
            disableUnderline
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="inputStyle"
            required
          />
          <input
            style={{ marginBottom: "1rem", width: "100%", height: "50px" }}
            type="tel"
            placeholder="Phone Number"
            fullWidth
            disableUnderline
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="inputStyle"
            required
            inputProps={{ pattern: "[0-9]{10}" }}
          />
        </div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            border: "2px solid #9B9999",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "14.5rem",
            marginTop: "15px",
            paddingBottom: "1rem"
          }}
          className="amountBtnDiv"
        >
          <div style={{ marginBottom: "0.8rem" }}>{"INR"}₹</div>
          <div style={{ marginBottom: "0.8rem" }}>
          <img src ={donate_img} width={150} style={{borderRadius:"10px"}}/>
          </div>

          <div className="inputWrapper">
            <input type="number"
              placeholder="Enter amount"
              fullWidth
              disableUnderline
              style={{ width: "9.6rem", paddingLeft: "1rem" }}
              value={customAmount}
              onChange={handleCustomAmountChange}
              className="customAmountStyle"

            />
          </div>
          <div style={{ display: "flex" }}>
            <Button
              sx={{ backgroundColor: "#909090" }}
              onClick={handleNextStep}
              style={{
                fontSize: "10px",
                fontWeight: "bold",
                paddingTop: "10px",
                color: "white",
                border: "none",
                backgroundColor: "#87BD42",
              }}
            >
              NEXT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  // =====================================
  const renderMonthlyForm = () => (
    <div className="scrolldiv">
      <DonationTypeToggle />
      <div>
        <div
          style={{
            marginTop: "2rem",
            marginBottom: "-10px",
            fontSize: "12px",
            fontStyle: "italic",
            fontFamily: "Poppins",
            color: "gray",
          }}
        >
          <FontAwesomeIcon
            icon={faShieldCat}
            style={{ color: "#8CC342", fontSize: "1rem", marginRight: "8px" }}
          />
          Your information is protected
        </div>
        <div
          style={{
            display: "grid",
            gap: "2.5rem",
            gridTemplateColumns: "3fr 1fr", // Adjusted width for first column
          }}
          className="contentMainDiv222"
        >
          {/* First column */}

          <div
            style={{
              gridTemplateColumns: "4fr 1fr", // Adjusted width for first column
            }}
          >
            <TextField
              placeholder="Name"
              fullWidth
              disableUnderline
              style={{ marginBottom: "1rem" }}
              className="inputStyle"
            />
            <TextField
              type="email"
              placeholder="E-mail ID"
              fullWidth
              disableUnderline
              style={{ marginBottom: "1rem" }}
              className="inputStyle"
            />
            <TextField
              type="tel"
              placeholder="Phone Number"
              fullWidth
              disableUnderline
              className="inputStyle"
            />
          </div>

          {/* Second column */}
          <div
            style={{
              backgroundColor: "white",
              border: "2px solid #9B9999",
              borderRadius: "6px",
              // marginTop: "1rem",
              padding: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Align items in center
              marginTop: "15px",
              height: "14.5rem",
            }}
            className="mainAmountDiv"
          >
            {/* INR dropdown */}
            <div style={{ marginBottom: "0.6rem" }}>{"INR"}₹</div>

            {/* Two rows of buttons */}
            <div style={{ marginBottom: "0.7rem" }}>
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <Button
                  variant={amount === "₹500" ? "contained" : "outlined"}
                  onClick={() => handleAmountClick("₹500")}
                  className="buttonsStyles"
                  style={{
                    width: "100%",
                    marginRight: "0.5rem",
                    color: "black",
                    border: "2px solid #9B9999",
                    borderRadius: "6px",
                  }} // Set width to 100%
                >
                  ₹500
                </Button>
                <Button
                  variant={amount === "₹1000" ? "contained" : "outlined"}
                  onClick={() => handleAmountClick("₹1000")}
                  className="buttonsStyles"
                  style={{
                    width: "100%",
                    color: "black",
                    border: "2px solid #9B9999",
                    borderRadius: "6px",
                  }} // Set width to 100%
                >
                  ₹1000
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  variant={amount === "₹2000" ? "contained" : "outlined"}
                  onClick={() => handleAmountClick("₹2000")}
                  className="buttonsStyles"
                  style={{
                    width: "100%",
                    marginRight: "0.5rem",
                    color: "black",
                    border: "2px solid #9B9999",
                    borderRadius: "6px",
                  }} // Set width to 100%
                >
                  ₹2000
                </Button>
                <Button
                  variant={amount === "₹5000" ? "contained" : "outlined"}
                  onClick={() => handleAmountClick("₹5000")}
                  className="buttonsStyles"
                  style={{
                    width: "100%",
                    color: "black",
                    border: "2px solid #9B9999",
                    borderRadius: "6px",
                  }} // Set width to 100%
                >
                  ₹5000
                </Button>
              </div>
            </div>
            <div className="inputWrapper">
              <Input
                placeholder="custom Amount"
                fullWidth
                disableUnderline
                style={{ width: "9.6rem" }}
                value={customAmount}
                onChange={handleCustomAmountChange}
                className="customAmountStyle"
                startAdornment={
                  // <InputAdornment position="start" className="adornment">
                  <CurrencyRupeeIcon sx={{ fontSize: 19 }} />
                  // </InputAdornment>
                }
              />
            </div>
            <Button
              fullWidth
              onClick={handleNextStep}
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                paddingTop: "10px",
                color: "white",
                border: "none",
                backgroundColor: "#87BD42",
                width: "9.7rem",
                borderRadius: "6px",
              }}
            >
              PROCEED TO PAY
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const renderOneTimeForm = () => (
    // <div className="contentMainDiv">
    //   <DonationTypeToggle />
    //   <div>
    //     <div
    //       style={{
    //         color: "#909090",
    //         fontSize: "12px",
    //         fontWeight: "400",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //       className="heading_top"
    //     >
    //       You can log in to edit your recurring donation anytime
    //     </div>
    //     <div
    //       style={{
    //         marginTop: "2rem",
    //         marginBottom: "-10px",
    //         fontSize: "12px",
    //         fontStyle: "italic",
    //         fontFamily: "Poppins",
    //         color: "gray",

    //       }}
    //     >
    //       <FontAwesomeIcon
    //         icon={faShieldCat}
    //         style={{ color: "#8CC342", fontSize: "1rem", marginRight: "8px" }}
    //       />
    //       Your information is protected
    //     </div>
    //     <div
    //       style={{
    //         display: "grid",
    //         gap: "2.5rem",
    //         gridTemplateColumns: "3fr 1fr",
    //         border: "none",
    //       }}
    //       className='contentMainDiv222'
    //     >
    //       {/* First column */}
    //       <div        
    //       >
    //         <TextField
    //           type="upiId"
    //           fullWidth
    //           id="upi-id"
    //           // label="UPI ID"
    //           placeholder="UPI ID (9009001111@upi)"
    //           value={upiId}
    //           onChange={(e) => setUpiId(e.target.value)}
    //           className="inputStyle"
    //           style={{
    //             marginBottom: "1.9rem",
    //           }}
    //         />
    //         {/* <TextField
    //           type="TextField"
    //           fullWidth
    //           id="card-number"
    //           // label="Card Number"
    //           placeholder="Card Number (0000 0000 0000) 00/00 CVV"
    //           value={cardNumber}
    //           onChange={(e) => setCardNumber(e.target.value)}
    //           className="inputStyle"
    //           style={{
    //             marginBottom: "1rem",
    //           }}
    //         /> */}
    //         <TextField
    //           value={cardInput}
    //           onChange={(e) => setCardInput(e.target.value)}
    //           fullWidth
    //           style={{ marginBottom: "1.5rem" }}
    //           placeholder="Enter card number, expiry date (MM/YY), CVV"
    //           className="inputStyle"

    //         />

    //         <TextField
    //           type="wallet"
    //           fullWidth
    //           id="wallets"
    //           // label="Wallets"
    //           placeholder="Wallets"
    //           value={wallets}
    //           onChange={(e) => setWallets(e.target.value)}
    //           className="inputStyle"
    //         />
    //       </div>

    //       {/* Second column */}
    //       <div
    //         style={{
    //           backgroundColor: "white",
    //           borderRadius: "10px",
    //           border: "2px solid #9B9999",
    //           padding: "5px",
    //           display: "flex",
    //           flexDirection: "column",
    //           alignItems: "center",
    //           height: "15rem",
    //           marginTop: "15px",
    //         }}
    //       >
    //         {/* INR dropdown */}
    //         <div style={{ padding: "8px" }}>{"INR"}₹</div>

    //         {/* Two rows of buttons */}
    //         <div style={{ marginBottom: "0.8rem" }}>
    //           <div style={{ display: "flex", marginBottom: "0.5rem" }}>
    //             <Button
    //               variant={amount === "₹500" ? "contained" : "outlined"}
    //               onClick={() => handleAmountClick("₹500")}
    //               className="buttonsStyles"
    //               style={{
    //                 width: "100%",
    //                 marginRight: "0.5rem",
    //                 color: "black",
    //                 border: "2px solid #9B9999",
    //                 borderRadius: "6px",
    //               }}
    //             >
    //               ₹500
    //             </Button>
    //             <Button
    //               variant={amount === "₹1000" ? "contained" : "outlined"}
    //               onClick={() => handleAmountClick("₹1000")}
    //               className="buttonsStyles"
    //               style={{
    //                 width: "100%",
    //                 color: "black",
    //                 border: "2px solid #9B9999",
    //                 borderRadius: "6px",
    //               }}
    //             >
    //               ₹1000
    //             </Button>
    //           </div>
    //           <div style={{ display: "flex" }}>
    //             <Button
    //               variant={amount === "₹2000" ? "contained" : "outlined"}
    //               onClick={() => handleAmountClick("₹2000")}
    //               className="buttonsStyles"
    //               style={{
    //                 width: "100%",
    //                 marginRight: "0.5rem",
    //                 color: "black",
    //                 border: "2px solid #9B9999",
    //                 borderRadius: "6px",
    //               }}
    //             >
    //               ₹2000
    //             </Button>
    //             <Button
    //               variant={amount === "₹5000" ? "contained" : "outlined"}
    //               onClick={() => handleAmountClick("₹5000")}
    //               className="buttonsStyles"
    //               style={{
    //                 width: "100%",
    //                 color: "black",
    //                 border: "2px solid #9B9999",
    //                 borderRadius: "6px",
    //               }}
    //             >
    //               ₹5000
    //             </Button>
    //           </div>
    //         </div>
    //         <div className="inputWrapper">
    //           <Input
    //             placeholder="custom Amount"
    //             fullWidth
    //             disableUnderline
    //             style={{ width: "9.6rem" }}
    //             value={customAmount}
    //             onChange={handleCustomAmountChange}
    //             className="customAmountStyle"
    //             startAdornment={
    //               // <InputAdornment position="start" className="adornment">
    //               <CurrencyRupeeIcon sx={{ fontSize: 19 }} />
    //               // </InputAdornment>
    //             }
    //           />
    //         </div>
    //         <Button
    //           fullWidth
    //           onClick={handleNextStep}
    //           style={{
    //             fontSize: "12px",
    //             fontWeight: "bold",
    //             paddingTop: "10px",
    //             color: "white",
    //             border: "none",
    //             backgroundColor: "#87BD42",
    //             width: "9.7rem",
    //             borderRadius: "6px",
    //           }}
    //         >
    //           PROCEED TO PAY
    //         </Button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div style={{ display: "flex", alignItems: '', gap: "3rem", backgroxxundColor: "cyan" }} className="mainBoxContainer">
        <img src={scanner_img} height={550} alt="scannerImg" className="scanImg" />
        <div>
          <div style={divStyle} className="maintablContainer">
            <div style={divStyle1}><b>Bank Account Details</b></div>
            <b>Name :</b> <span> TRISAKHA FOUNDATION </span><br /><br />
            <b >Account Number :</b><span style={{ fontSize: "20px", fontFamily: "poppins" }}> 50200096287420</span><br /><br />
            <b>IFS Code :</b> <span> HDFC0004166 </span><br /><br />
            <b>Bank :</b> <span>HDFC Bank </span> <br /><br />
            <b>Branch :</b> <span> Ramanujam IT Park, Taramani, Chennai </span><br />
            <div className='buttonParentDiv'>
            <button className="donate_submit_btn" onClick={modalsubmit} >Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const renderProcessing = () => (
    <div style={{ textAlign: "center" }}>
      <h4 style={{ fontFamily: "Poppins" }}>Processing Payment</h4>
      <div style={{ fontSize: "2rem", margin: "1rem 0" }}>{timeLeft}s</div>
      <div className="mainMobileUi">
        <div className="mobileUi"></div>
      </div>
      <div className="stepstyling">Steps</div>
    </div>
  );

  const renderConfirmation = () => (
    <div style={{ textAlign: "center" }}>
      <img
        src={successfullImg}
        alt="payment_successfully_img"
        className="successImg"
      />
      <p style={{ fontFamily: "Poppins", fontSize: "1.5rem" }}>
        Payment Successful
      </p>
      <p
        style={{
          fontFamily: "Poppins",
          color: "#909090",
          fontSize: "1rem",
          fontStyle: "italic",
        }}
      >
        A receipt of the donation will be sent to your email id
      </p>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.reload();
        }}
      >
        CLOSE
      </Button> */}
    </div>
  );

  return (
    <Modal
      className="donatemodal"
      open={open}
      closeAfterTransition
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClick={stopPropagation} // Prevent closing on clicks inside modal content
    >
      <Box
        sx={outerStyle}
        className="box1"
        ref={boxRef}
        onClick={stopPropagation}
      >
        <CloseIcon onClick={handleCloseModal} className="clear" />
        <div className="steppers">
          <div
            className="stepperFirst"
            style={{ backgroundColor: step === 1 ? "#909090" : "#c2c2c2" }}
          >
            1
          </div>
          <div className="stepperArrow">{">"}</div>
          <div
            className="stepperSecond"
            style={{
              backgroundColor:
                step === 2 || step === 3 || step === 4 ? "#909090" : "#c2c2c2",
            }}
          >
            2
          </div>
        </div>
        <Box sx={innerStyle} className="box2">
          <div className="scrolldiv">
            {step === 1 && (
              <>
                <DonationTypeToggle />
                <div
                  style={{
                    color: "#909090",
                    fontSize: "12px",
                    fontWeight: "400",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="heading_top"
                >
                  You can log in to edit your recurring donation anytime
                </div>
                {renderFirstForm()}
              </>
            )}
            {step === 2 &&
              (donationType === "One-Time"
                ? renderMonthlyForm()
                : renderOneTimeForm())}
            {step === 3 && renderProcessing()}
            {step === 4 && renderConfirmation()}
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default DonateModal;
