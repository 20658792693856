// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import About from './pages/About';
// import Initiatives from './pages/Initiatives';
// import Blogs from './pages/Blogs';
// import Training from './pages/Training';
import Footer from './Component/Footer';
import About from './Component/About';
import Homepage from './Component/Homepage';
import Initiative from './Component/Initiative';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import Blogs from './Component/Blogs';
import Initiative2 from './Component/Initiative2';

const App = () => {
  return (
    <>
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/* <Route path="/" element={<Header />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/initiatives" element={<Initiative/>} />
        {/* <Route path="/initiatives" element={<Initiative2 />} /> */}
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/training" element={<Homepage />} />
        {/* <Route path="/initiatives" element={<Initiatives />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/training" element={<Training />} /> */}
      </Routes>
    <Footer/>

    </Router>
    <ToastContainer 
        position="top-center" 
        autoClose={5000}      
        hideProgressBar={false}
        newestOnTop={false}   
        closeOnClick           
        rtl={false}            
        pauseOnFocusLoss       
        draggable             
        pauseOnHover          
      />
    </>

  );
};

export default App;
