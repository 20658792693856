import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/FormComponent.css";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export default function FormComponent() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    org_name: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.first_name === '' || formData.last_name === '' || formData.phone_number === '' || formData.email === '' || formData.org_name === '' || formData.message === '') {
      alert("Please Provide All Details !")
    } else {
        axios.post(`https://trisakha.org/upload`, formData, {


        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.status);
            toast.success("Our Team Will Contact You Soon!", {
              autoClose: 5000,
            });
            setTimeout(() => {
              window.location.reload()
    
            }, 5000);
          }
        })
        .catch((error) => {
          alert("Some Error Occured Contact Admin")
          console.log(error);
        });
    }

    console.log(formData);
  };

  return (
    <>

      <h1 className='div6_h' align='center' style={{ paddingBottom: "3rem", paddingTop: "2rem", backgroundColor: "white", margin: "0px" }}>SCHEDULE A MEETING</h1>

      <div className='div_6' style={{ backgroundColor: "" }}>
        <div style={{ paddingTop: "0rem", background: "linear-gradient(180deg, rgba(255,255,255,1) 27%, rgba(255,255,255,0) 100%)" }}>


          <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", }}>
            <div className="grid-container2">
              <form className="custom-form" onSubmit={handleSubmit}>
                <div className="column column-1">
                  <div className="name-fields">
                    <div className="name-field">
                      <label style={{ fontWeisssght: "bold", color: "#141C48" }} htmlFor="first-name">First Name</label>
                      <input
                      className='form_input'
                        type="text"
                        id="first-name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }}
                      />
                    </div>
                    <div className="name-field">
                      <label style={{ fontWeisssght: "bold", color: "#141C48" }} htmlFor="last-name">Last Name</label>
                      <input
                      className='form_input'
                        type="text"
                        id="last-name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }}
                      />
                    </div>
                  </div>

                  <label style={{ fontWeisssght: "bold", color: "#141C48" }} htmlFor="email">Email ID</label>
                  <input
                  className='form_input'
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }}
                  />

                  <label style={{ fontWeisssght: "bold", color: "#141C48" }} htmlFor="phone">Phone Number</label>
                  <input
                  className='form_input'
                    type="tel"
                    id="phone"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }}
                  />
                </div>
                <div className="column column-2">
                  <label style={{ fontWeisssght: "bold", color: "#141C48" }} htmlFor="organization">Organization Name</label>
                  <input
                  className='form_input'
                    type="text"
                    id="organization"
                    name="org_name"
                    value={formData.org_name}
                    onChange={handleChange}
                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black" }}
                  />

                  <label style={{ fontWeisssght: "bold", color: "#141C48" }} htmlFor="message">Message</label>
                  <textarea
                  className='form_input'
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    style={{ backgroundColor: "rgb(245, 245, 245,0.5)", border: "1px solid black", height: "110px" }}
                  ></textarea>
                  <div className="submit-container1" style={{ marginTop: "2rem", marginLeft: "-4rem", backgroundColor: "" }}>
                    <button type="submit" style={{padding:"5px 30px"}} onClick={handleSubmit}>SUBMIT</button>
                  </div>
                </div>

              </form>
            </div>

          </div>
        </div>

      </div>
      {/* </div> */}
      {/* // </div> */}
    </>
  );
}
