import React from "react";
// import Headers from "../components/Header";
import Content from "./Content";
// import "../css/Initiative.css";

function Initiative() {
  return (
    <div>
      <Content />
    </div>
  );
}

export default Initiative;
