import React from "react";
import logo from "../assets/trisakha_logo.png";
import "../css/Footer.css";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate('/');
};
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer">
          <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="#objectives">Objectives</a>
          <a href="#Projects">Projects</a>
          <a href="#Training">Training</a>
        </div>
        <div className="vertical-line"></div>
        <div className="footer-column">
          <div className="footer">
            <a href="#Terms&Conditions">Terms & Conditions</a>
            <a href="#PrivacyPolicy">Privacy Policy</a>
            <a href="#Disclaimer">Disclaimer</a>
          </div>
        </div>
      </div>
      <div className="footer-logo">
        <img onClick={goToHome} className="footer_img" src={logo} />
        <p id="footer_p">© 2024 Trisakha Foundation</p>
      </div>
    </div>
  );
};

export default Footer;
